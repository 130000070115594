import styled, { css } from "styled-components";

export default styled.button`
  background: ${({ theme }) => theme.colors.primary.main};
  color: #FFF;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
  height: 52px;
  border: 2px solid #FFF;
  border-radius: 4px;
  outline: none;
  padding: 0 16px;
  font-size: 16px;
  transition: background all 0.2s ease-in;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover{
    background: ${({ theme }) => theme.colors.primary.light};
  }

  &:active{
    background: ${({ theme }) => theme.colors.primary.dark};
  }

  &[disabled]{
    background: #CCC;
    border: 2px solid #CCC;
    cursor: default;
  }

  ${({ theme, danger }) => danger && css`
    background: ${theme.colors.danger.main};

    &:hover{
      background: ${theme.colors.danger.light};

      &[disabled]{
        background: #CCC;
        border: 2px solid #CCC;
        cursor: default;
      }
    }

    &:active{
      background: ${theme.colors.danger.dark};
    }
  `};

  ${({ theme, neutral }) => neutral && css`
    background: #000;

    &:hover{
      background: ${theme.colors.gray[900]};
    }

    &:active{
      background: ${theme.colors.gray[200]};
    }
  `};
`;