import styled from "styled-components";

export const CardsContainer = styled.div`
  margin-bottom: 72px;
`;

export const Card = styled.div`
  background: #FFF;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
  padding: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + &{
    margin-top: 16px;
  }
  
  .info {
    width: 100%;
    max-width: 205px;
    /* max-width: 60%; */

    .appointment-name {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      font-size: 16px;
      width: 245px;
      
      strong{
        inline-size: 245px; 
        overflow-wrap: break-word;
        height: auto;
      }

      small {
        background: ${({ theme }) => theme.colors.primary.lighter};
        color: ${({ theme }) => theme.colors.primary.main};
        text-transform: uppercase;
        padding: 4px;
        border-radius: 4px;
        margin-left: 8px;
      }
    }

    .span{
      display: block;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.gray[200]};
      margin-bottom: 12px;
    }
  }

  .actions{ 
    width: 100%;
    display: flex;
    /* align-items: center;
    justify-content: space-around; */
    align-items: center;
    justify-content: flex-end;

    button{
      background: transparent;
      border: none;
      /* margin-left: 8px; */
      margin-right: 8px;
      margin-left: 8px;
      cursor: pointer;
    }

    img{
      width: 32px;
    }
  }
`;

export const ListContainer = styled.div`
  margin-top: 24px;

  header{
    margin-bottom: 8px;

    .sort-button{
      background: transparent;
      border: none;
      display: flex;
      align-items: center;

      span {
        margin-right: 8px;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.primary.main};

        img{
          height: 8px;
        }
      }
    }
  }

  justify-content: left;
  display: flex;
  flex-direction: column;

  img{
    height: 74px;
  }
`;

export const Container = styled.div`
  margin-top: 32px;
  position: relative;

  .fab-container{
    display:flex;
    justify-content:flex-end;
    position:fixed;
    bottom:30px;
    width: 100%;
    max-width: 400px;
    left: 50%;
    transform: translate(-50%, 0);
    /* background: black; */
    padding-right: 24px;

    .iconbutton{
      width:50px;
      height:50px;
      border-radius: 100%;
      background: ${({ theme }) => theme.colors.primary.main};
      box-shadow: 10px 10px 5px #aaaaaa;
      cursor:pointer;
      
      .options{
        list-style-type: none;
        position:absolute;
        bottom:70px;
        right:0;
        display:flex;
        flex-direction: column;
        justify-content:flex-end;

        li{
          display:flex;
          justify-content:flex-end;
          padding:5px;
          
          a{
            text-decoration: none;
            color:#FFF;
            padding:12px 15px;

            .btn-label{
              padding:12px 15px;
              margin-right:10px;
              width: 100%;
              white-space: nowrap;
              align-self: center;
              user-select:none;
              background-color: #000000;
              color:#FFF;
              border-radius: 3px;
              box-shadow: 10px 10px 5px #aaaaaa;
            }
          }
        }
      }
      
      .icon{
        display:flex;
        align-items:center;
        justify-content:center;
        height: 100%;
        color:#FFF;
        font-size: 32px;

        img{
          height: 24px;
          
          &.close{
            transform: rotate(45deg);
          }
        }
      }

      &.button{
        width:60px;
        height:60px;
        background: ${({ theme }) => theme.colors.primary.main};
      }
    }
  }
`;

export const ErrorContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222;

  strong{
    color: ${({ theme }) => theme.colors.danger.main};
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray[100]};
  padding-bottom: 16px;

  strong{
    color: #222;
    font-size: 16px;
  }

  a{
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: none;
    font-weight: bold;
    padding: 8px 16px;
    border: 2px solid ${({ theme }) => theme.colors.primary.main};
    border-radius: 4px;
    transition: all 0.2s ease-in;

    &:hover{
      background: ${({ theme }) => theme.colors.primary.light};
      border: 2px solid ${({ theme }) => theme.colors.primary.light};
      color: #FFF;
    }

  }
`;