import HttpClient from './utils/HttpClient';

class AppointmentsService {
  constructor(baseUrl) {

    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async listAppointments(userId, fetchOnlyActive) {
    // const urlPath = '/appointments/get-all{}';
    const urlPath = `/appointments/get-all/${userId}/status/${fetchOnlyActive}`;

    return this.httpClient.get(urlPath);
  }

  async listAppointmentsNotShared(userId, fetchOnlyActive) {
    // const urlPath = '/appointments/get-all{}';
    const urlPath = `/appointments/get-all-not-shared/${userId}/status/${fetchOnlyActive}`;

    return this.httpClient.get(urlPath);
  }

  async GetAllFiltered(request) {
    // const urlPath = '/appointments/get-all{}';
    const urlPath = `/appointments/get-all-filtered`;

    return this.httpClient.post(urlPath, request);
  }

  async getAppointment(id) {
    const urlPath = `/appointments/get/${id}`;

    return this.httpClient.get(urlPath);
  }

  // async createAppointment(appointment) {
  //   const urlPath = '/appointments';

  //   return this.httpClient.post(urlPath, appointment);
  // }

  // async updateAppointment(appointment) {
  //   const urlPath = '/appointments/update';

  //   return this.httpClient.post(urlPath, appointment);
  // }

  async createAppointmentFormData(appointment) {
    const urlPath = '/appointments';

    return this.httpClient.postFormData(urlPath, appointment);
  }

  async updateAppointmentFormData(appointment) {
    const urlPath = '/appointments/update';

    return this.httpClient.postFormData(urlPath, appointment);
  }

  async deleteAppointment(appointment) {
    const urlPath = '/appointments/delete';

    return this.httpClient.post(urlPath, appointment);
  }

  async deleteAppointmentAttachment(appointment) {
    const urlPath = '/appointments/delete-attachment';

    return this.httpClient.post(urlPath, appointment);
  }
}

export default AppointmentsService;