/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, Header, ListContainer, Card, ErrorContainer, CardsContainer } from './styles';
import Trash from '../../assets/icons/trash.svg';
import Edit from '../../assets/icons/edit.svg';
import Share from '../../assets/icons/share.svg';
// import ArrowUp from '../../assets/icons/arrow-up.svg';
// import { InputSearchContainer } from '../Header/styles';
import Loader from '../Loader';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { format } from 'date-fns';
import AppointmentsService from '../../services/AppointmentsService';
import Button from '../Button';
import Modal from '../Modal';
import ModalOptions from '../ModalOptions';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
import PlusSign from '../../assets/icons/plus-sign.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFolder,
  faCalendarDay,
  faBell,
  faInfoCircle,
  faRecycle,
  // faDollarSign,
} from '@fortawesome/free-solid-svg-icons'
// // https://fontawesome.com/v5/cheatsheet
import PropTypes from 'prop-types';

export default function Appointments({ fetchOnlyActive, appointmentTypeId, startDate, endDate }) {
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  const [appointmentToShare, setAppointmentToShare] = useState(null);
  const [isOptionsVisible, setOptionsVisible] = useState(false);
  const [plusSignClass, setPlusSignClass] = useState("");
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const listAppointments = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentsList = await new AppointmentsService(Config.API_BASE_URL).GetAllFiltered(
        {
          UserId: sessionObject.userId,
          FetchOnlyActive: fetchOnlyActive,
          AppointmentTypeId: appointmentTypeId,
          StartDate: startDate,
          EndDate: endDate,
        }
      );
      setAppointments(appointmentsList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject.userId, fetchOnlyActive, appointmentTypeId, startDate, endDate]);

  async function handleReload() {
    await listAppointments();
  };

  useEffect(() => {
    listAppointments();
  }, [listAppointments]);

  async function handleDelete(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);

    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
      OriginalAppointmentId: appointmentToDelete.OriginalAppointmentId ?? appointmentToDelete.Id,
    });

    await handleReload();
  }

  async function handleDeleteRecurrenceOnly(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);

    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
    });

    await handleReload();
  }

  async function handleShareModal(e) {
    setIsShareModalOpen(false);
    setIsLoading(true);
    window.open(`/shared/${appointmentToShare.Id}`, '_self');
  }

  function handleOpenModal(e, appointment) {
    setAppointmentToDelete(appointment);
    setIsModalOpen(true);
  }

  function handleOpenShareModal(e, appointment) {
    setAppointmentToShare(appointment);
    setIsShareModalOpen(true);
  }

  function handleCloseShareModal(e, appointment) {
    setIsShareModalOpen(false);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  function handleOpenModalOptions(e, appointment) {
    setAppointmentToDelete(appointment);
    setIsModalOptionsOpen(true);
  }

  function handleCloseModalOptions() {
    setIsModalOptionsOpen(false);
  }

  function handleOptions(e) {
    setOptionsVisible(!isOptionsVisible);
    setPlusSignClass(plusSignClass === '' ? 'close' : '');
  }

  return (
    <Container>
      <Loader isLoading={isLoading} />

      <Modal
        title='Compartilhar compromisso'
        body='Tem certeza que deseja compartilhar o compromisso?'
        isVisible={isShareModalOpen}
        // danger
        onConfirm={handleShareModal}
        onCancel={handleCloseShareModal}
      />

      <Modal
        title='Tem certeza que deseja remover o compromisso?'
        body='Esta ação não poderá ser desfeita'
        isVisible={isModalOpen}
        danger
        onConfirm={handleDelete}
        onCancel={handleCloseModal}
      />

      <ModalOptions
        title='Tem certeza que deseja remover o compromisso?'
        body='[IMPORTANTE] Este compromisso possui recorrências'
        isVisible={isModalOptionsOpen}
        danger
        onCancel={handleCloseModalOptions}
        onOption1={handleDelete}
        onOption2={handleDeleteRecurrenceOnly}
        option1Text='Apagar todos'
        option2Text='Apagar somente esta instância'
      />

      {/* <InputSearchContainer>
        <input type="text" placeholder='pesquisar compromisso' />
      </InputSearchContainer> */}

      <Header>
        <strong>
          {appointments.length}
          {appointments.length === 1 ? ' compromisso' : ' compromissos'}
        </strong>
        {/* <Link to='/new'>Novo compromisso</Link> */}
      </Header>
      <ListContainer>
        {/* <header>
          <button type='button' className='sort-button'>
            <span>Nome <img src={ArrowUp} alt='Arrow Up' /></span>
          </button>
        </header> */}
      </ListContainer>

      <div className="fab-container">
        <div
          className="button iconbutton"
          onClick={handleOptions}
        >
          <span className='icon'>
            {/* {optionsIcon} */}
            <img src={PlusSign} alt='Plus sign' className={`${plusSignClass}`} />
          </span>

          {isOptionsVisible && (
            <ul className="options">
              <li>
                <a href="/new/4">
                  <span className="btn-label">
                    Documento com vencimento
                  </span>
                </a>
              </li>

              <li>
                <a href="/new/5">
                  <span className="btn-label">
                    Documento com pagamento
                  </span>
                </a>
              </li>

              <li>
                <a href="/new/6">
                  <span className="btn-label">
                    Agendamento geral
                  </span>
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>

      {hasError && (
        <ErrorContainer>
          <strong>
            Ocorreu um erro ao retornar os compromissos
          </strong>
          <Button
            type="button"
            onClick={(e) => { handleReload(); }}
          >
            Tentar novamente
          </Button>
        </ErrorContainer>
      )}

      {!hasError && (
        <>
          <CardsContainer>
            {appointments && (
              appointments.map((appointment) => (

                <Card
                  key={`id-${appointment.Id}`}
                >
                  <div className="info">
                    {/* <div className="appointment-name">
                      <strong>{appointment.AppointmentCategoryName}</strong>
                    </div> */}

                    <div className="span">
                      <FontAwesomeIcon icon={faFolder} />&nbsp;&nbsp;
                      <strong>
                        {appointment.AppointmentTypeName}
                      </strong>
                    </div>

                    <div className="span">
                      <FontAwesomeIcon icon={faCalendarDay} />&nbsp;&nbsp;
                      <b>
                        {format(new Date(appointment.AppointmentDate), 'dd/MM/yyyy')}

                        {appointment.AppointmentTime && (
                          ` - ${appointment.AppointmentTime}`
                        )}
                      </b>
                    </div>

                    {appointment.ReminderDates && (
                      <div className="span">
                        {
                          appointment.ReminderDates[0] && (
                            <>
                              <FontAwesomeIcon icon={faBell} />&nbsp;&nbsp;
                              {format(new Date(appointment.ReminderDates[0]), 'dd/MM/yyyy')}
                            </>
                          )
                        }

                        {
                          appointment.ReminderDates[1] && (
                            <>
                              , {format(new Date(appointment.ReminderDates[1]), 'dd/MM/yyyy')}
                            </>
                          )
                        }

                        {
                          appointment.ReminderDates[2] && (
                            <>
                              &nbsp;e {format(new Date(appointment.ReminderDates[2]), 'dd/MM/yyyy')}
                            </>
                          )
                        }

                      </div>
                    )}

                    {/* {
                      (appointment.DocumentValue !== 0) && (appointment.DocumentValue) && (
                        <>
                          <div className="span">
                            {appointment.DocumentValuestring}
                          </div>
                        </>
                      )
                    } */}

                    {appointment.Observations && (
                      <div className="span">
                        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;
                        {appointment.Observations}
                      </div>
                    )}

                    {appointment.AppointmentHasRecurrences && (
                      <div className="span">
                        <FontAwesomeIcon icon={faRecycle} />&nbsp;&nbsp;
                        Compromisso recorrente
                      </div>
                    )}

                  </div>

                  <div className="actions">
                    <a href={`/edit/${appointment.Id}`}>
                      <img src={Edit} alt='Edit' />
                    </a>

                    {(!appointment.SharedAppointmentId) && (
                      <>
                        <button
                          type="button"
                          onClick={(e) => {
                            handleOpenShareModal(e, appointment);
                          }}
                        >
                          <img src={Share} alt='Share' />
                        </button>

                        <button
                          type="button"
                          onClick={(e) => {
                            // handleDelete(e, appointment.Id)
                            if (!appointment.AppointmentHasRecurrences) {
                              handleOpenModal(e, appointment);
                            }
                            else {
                              handleOpenModalOptions(e, appointment);
                            }
                          }}
                        >
                          <img src={Trash} alt='Trash' />
                        </button>
                      </>
                    )}
                  </div>
                </Card>
              ))
            )}
          </CardsContainer>
        </>
      )}
    </Container >
  );
}

Appointments.propTypes = {
  fetchOnlyActive: PropTypes.bool.isRequired,
  appointmentTypeId: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

// Appointments.defaultProps = {
//   error: null,
// }