import { useState } from 'react';
import { Form, ButtonContainer } from './styles';
import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';
import useErrors from '../../hooks/useErrors';
import Loader from '../Loader';
import { localGet, localSet } from '../../lib/session';
import Config from '../../config/environments/local';
import UsersService from '../../services/UsersService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
  // faDollarSign,
} from '@fortawesome/free-solid-svg-icons'

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const {
    // errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = (email && password);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionObject, setSessionObject] = useState(localGet(Config.SESSION_KEY));

  if (sessionObject) {
    window.open('/', '_self');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    const response = await new UsersService(Config.API_BASE_URL).login({
      Email: email,
      Password: password,
    });

    if (response.Error) {
      setError({ field: 'email', message: response.Error.Message });
      // setError({ field: 'password', message: response.Error.Message });
      setIsLoading(false);
    } else {
      removeError('email');
      // removeError('password');
      const sessionObject = {
        userId: response.Result.Id,
        userName: response.Result.Name,
        userEmail: response.Result.Email,
      };

      localSet(Config.SESSION_KEY, sessionObject, Config.EXPIRATION_AUTH);
      setSessionObject(sessionObject);
    }
    // if (password !== Config.DEFAULT_USER_OBJECT.password) {
    //   setError({ field: 'password', message: 'senha incorreta' });
    //   setIsLoading(false);
    // }
    // else {
    //   removeError('password');
    //   localSet(Config.SESSION_KEY, Config.DEFAULT_USER_OBJECT, Config.EXPIRATION_AUTH);
    //   setSessionObject(Config.DEFAULT_USER_OBJECT);
    // }
  }

  // async function handleGoToRegister(e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setIsLoading(true);

  //   window.open('/users/new', '_self');
  // }

  async function handleRegister(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    window.open("/users/new", "_self");
  }

  function handleChangeEmail(e) {
    setEmail(e.target.value);

    if (!e.target.value) {
      setError({ field: 'email', message: 'O e-mail é obrigatório' });
    } else {
      removeError('email');
    }
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);

    if (!e.target.value) {
      setError({ field: 'password', message: 'A senha é obrigatória' });
    } else {
      removeError('password');
    }
  }

  return (

    <Form onSubmit={handleSubmit} noValidate>
      {/* <div>E-mail</div> */}
      <FormGroup
        error={getErrorMessageByFieldName('email')}
      >
        <div className='form-label'>Email</div>
        <Input
          type="text"
          value={email}
          placeholder="Seu email"
          onChange={handleChangeEmail}
          error={getErrorMessageByFieldName('email')}
        />
      </FormGroup>

      <FormGroup
        error={getErrorMessageByFieldName('password')}
      >
        <div className='form-label'>Senha</div>
        <div className="input-group-btn">
          <Input
            type={passwordType}
            value={password}
            placeholder="Sua senha de acesso"
            onChange={handleChangePassword}
            error={getErrorMessageByFieldName('password')}
          />
          <button type="button" className="btn" onClick={togglePassword}>
            {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
          </button>
        </div>
      </FormGroup>

      <Loader isLoading={isLoading} />

      <ButtonContainer>
        <Button
          type="button"
          disabled={!isFormValid}
          // onClick={() => loginWithRedirect()}
          onClick={(e) => handleSubmit(e)}

        >
          Logar
        </Button>
        {/* <Button
          type="button"
          // onClick={() => loginWithRedirect()}
          onClick={(e) => handleGoToRegister(e)}

        >
          Não possui conta? Cadastre-se aqui
        </Button> */}
      </ButtonContainer>

      <FormGroup>
        <div className='anchor-container'>
          <a
            href="/forgot-password"
          >
            Lembrar minha senha
          </a>
        </div>
      </FormGroup>

      <ButtonContainer>
        <Button
          type="button"
          // onClick={() => loginWithRedirect()}
          onClick={(e) => handleRegister(e)}

        >
          Quero me cadastrar
        </Button>

      </ButtonContainer>
    </Form >
  );
}
