import { useParams } from 'react-router-dom';
import PageHeader from "../../components/PageHeader";
import AppointmentsService from '../../services/AppointmentsService';
import SharedAppointmentsService from '../../services/SharedAppointmentsService';
import { useState, useEffect } from "react";
import { Card, CardsContainer, Header } from './styles';
import Loader from '../../components/Loader';
import { format } from 'date-fns';
import Config from '../../config/environments/local';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faRefresh,
  faFolder,
  faCalendarDay,
  faBell,
  faInfoCircle,
  faRecycle,
  // faDollarSign,
} from '@fortawesome/free-solid-svg-icons'
import { localGet } from "../../lib/session";

export default function SharedAppointment() {

  const [appointment, setAppointment] = useState({});
  const [sharedWithUsers, setSharedWithUsers] = useState([]);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const [isLoading, setIsLoading] = useState(true);
  const {
    id,
    // viewerUserId,
  } = useParams();

  async function handleDelete(sharedAppointmentId) {
    try {
      setIsLoading(true);

      const response = await new SharedAppointmentsService(Config.API_BASE_URL).delete({
        Id: sharedAppointmentId,
      });

      console.log(response);

      if (response.Error !== null) {
        window.open(`/shared/${id}`, '_self');
      }
      else {
        window.open(`/shared/${id}`, '_self');
      }

      // setAppointment(response.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function getAppointment() {
      try {
        setIsLoading(true);

        const response = await new AppointmentsService(Config.API_BASE_URL).getAppointment(id);

        response.Result.AppointmentDate = format(new Date(response.Result.AppointmentDate), 'dd/MM/yyyy');

        if (response.Result.AppointmentRecurrenceEndDate) {
          response.Result.AppointmentRecurrenceEndDate = format(new Date(response.Result.AppointmentRecurrenceEndDate), 'dd/MM/yyyy');
        }

        if (response.Result.ReminderDates) {
          for (let i = 0; i < response.Result.ReminderDates.length; i++) {
            const formattedDate = format(new Date(response.Result.ReminderDates[i]), 'dd/MM/yyyy');
            response.Result.ReminderDates[i] = formattedDate;
          }
        }

        setAppointment(response.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }

    async function getSharedWithUsers() {
      try {
        setIsLoading(true);

        const response = await new SharedAppointmentsService(Config.API_BASE_URL).getByAppointmentIdAndOwnerUserId(id, sessionObject.userId);

        setSharedWithUsers(response.Result);

      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }

    getAppointment();
    getSharedWithUsers();
  }, [id, sessionObject.userId]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <PageHeader
        title='Gerenciar compartilhamentos'
      />
      <CardsContainer>
        {appointment && (
          <Card
            key={`id-${appointment.Id}`}
          >
            <div className="info">
              <div className="appointment-name">
                <strong>{appointment.AppointmentCategoryName}</strong>
              </div>

              <div className="span">
                <FontAwesomeIcon icon={faFolder} />&nbsp;&nbsp;
                <strong>
                  {appointment.AppointmentTypeName}
                </strong>
              </div>

              <div className="span">
                <FontAwesomeIcon icon={faCalendarDay} />&nbsp;&nbsp;
                <b>
                  {appointment.AppointmentDate}

                  {appointment.AppointmentTime && (
                    ` - ${appointment.AppointmentTime}`
                  )}
                </b>
              </div>

              {appointment.ReminderDates && (
                <div className="span">
                  {
                    appointment.ReminderDates[0] && (
                      <>
                        <FontAwesomeIcon icon={faBell} />&nbsp;&nbsp;
                        {appointment.ReminderDates[0]}
                      </>
                    )
                  }

                  {
                    appointment.ReminderDates[1] && (
                      <>
                        , {appointment.ReminderDates[1]}
                      </>
                    )
                  }

                  {
                    appointment.ReminderDates[2] && (
                      <>
                        &nbsp;e {appointment.ReminderDates[2]}
                      </>
                    )
                  }

                </div>
              )}

              {
                (appointment.DocumentValue !== 0) && (appointment.DocumentValue) && (
                  <>
                    <div className="span">
                      {appointment.DocumentValuestring}
                    </div>
                  </>
                )
              }

              {appointment.Observations && (
                <div className="span">
                  <FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;
                  {appointment.Observations}
                </div>
              )}

              {appointment.AppointmentHasRecurrences && (
                <div className="span">
                  <FontAwesomeIcon icon={faRecycle} />&nbsp;&nbsp;
                  Compromisso recorrente
                </div>
              )}

            </div>
          </Card>
        )}
      </CardsContainer>

      <br />
      <br />

      <Header>
        Compartilhando atualmente com:
      </Header>

      <div className='shared-users-container'>
        {sharedWithUsers && (
          <>
            {
              sharedWithUsers.map((sharedAppointment) => (
                <>
                  <br />
                  <br />
                  {/* <div className='row'> */}
                  <Card>
                    <div className='info'>
                      {sharedAppointment.SharedUserName ? sharedAppointment.SharedUserName : sharedAppointment.SharedUserEmail}
                    </div>
                    <div className='actions'>
                      <button
                        type="button"
                        onClick={(e) => {
                          handleDelete(sharedAppointment.Id)
                        }}
                      >
                        <FontAwesomeIcon icon={faRefresh} />&nbsp;&nbsp;
                        Desfazer
                      </button>
                    </div>
                  </Card>
                </>
              ))
            }
          </>
        )}
      </div>

      <div>
        <br />
        <br />

        <a
          href="/shared"
        >
          Compartilhar com mais pessoas
        </a>
      </div>
    </>
  )
};