import { useState } from "react";


export default function useErrors() {
  const [errors, setErrors] = useState([]);

  function setError({ field, message }) {

    const errorAlreadyExists = errors.find((error) => error.field === field);

    if (errorAlreadyExists) {
      const errorAndMessageAlreadyExists = errors.find((error) => error.field === field && error.message === message);
      if (errorAndMessageAlreadyExists) {
        return;
      }
      else {
        setErrors((prevState) => prevState.filter(
          (error) => error.field !== field
        ));

        setErrors((prevState) => [
          ...prevState,
          { field, message }
        ]);
      }
    }

    setErrors((prevState) => [
      ...prevState,
      { field, message }
    ]);
  }

  function removeError(field) {
    setErrors((prevState) => prevState.filter(
      (error) => error.field !== field
    ));
  }

  function getErrorMessageByFieldName(fieldName) {
    return errors.find((error) => error.field === fieldName)?.message;
  }

  return {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  };
}
