import styled from "styled-components";

export const Container = styled.header`
  margin-bottom: 24px;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */

  a{
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: left;
    width: 100%;
    max-width: 70px;

    span{
      color: ${({ theme }) => theme.colors.primary.main};
      font-weight: bold;
    }

    img{
      width: 16px;
      margin-right: 8px;
    }
  }

  h1{
    font-size: 24px;
  }
`;