import UserForm from "../../components/UserForm";
// import { Container, Header } from './styles';
import UsersService from '../../services/UsersService';
import Config from '../../config/environments/local';
import PageHeader from "../../components/PageHeader";
// import Loader from '../../components/Loader';

export default function NewUser() {

  // const [isLoading, setIsLoading] = useState(true);

  async function handleSubmit(formData) {

    const response = await new UsersService(Config.API_BASE_URL).Create({
      Name: formData.name,
      Email: formData.email,
      Password: formData.password,
    });

    return response;
  }

  return (
    <>
      {/* <Loader isLoading={isLoading} /> */}
      <PageHeader
        title='Criar usuário'
      />
      <UserForm
        onSubmit={handleSubmit}
      />
    </>
  )
};