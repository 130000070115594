// import { Container, TopContainer } from './styles';
// import { TopContainer } from './styles';
// import logo from '../../assets/images/logo.svg';
import Logo from '../../assets/images/logotipo.svg';
import FooterFacebook from "../../assets/icons/footer-facebook-f-brands.svg";
import FooterInstagram from "../../assets/icons/footer-instagram-brands.svg";
import FooterLinkedin from "../../assets/icons/footer-linkedin-in-brands.svg";
import FooterYoutube from "../../assets/icons/footer-youtube-brands.svg";
// import { localGet, localRemove } from "../../lib/session";
// import { useState } from "react";
// import Config from "../../config/environments/local";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faUser
// } from '@fortawesome/free-solid-svg-icons'

export default function Header() {
  // const [sessionObject, setSessionObject] = useState(localGet(Config.SESSION_KEY));

  // if (!sessionObject) {
  //   // const location = window.location.pathname;

  //   // if (
  //   //   (!location.includes('login')) &&
  //   //   (!location.includes('users')) &&
  //   //   (!location.includes('forgot-password')) &&
  //   //   (!location.includes('reset-password'))
  //   // ) {
  //   //   window.open('/login', '_self');
  //   // }
  // }

  // function handleLogout() {
  //   localRemove(Config.SESSION_KEY);
  //   setSessionObject(null);
  // }

  // function handleGetReport(e) {
  //   e.preventDefault();

  //   window.open(`${Config.KROONAR_PRINT_URL}${sessionObject.userId}`, '_blank');
  // }

  // const navDropdownTitle = (`<div><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" class="svg-inline--fa fa-user " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"></path></svg> 
  // ${sessionObject.userName}</div>`);

  // const setHtml = () => {
  //   return { __html: navDropdownTitle };
  // }

  return (
    <>
      <Navbar fixed="top" bg="light" expand="lg"
      // className='navbar-expand'
      >
        <Container className='menu-container'>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Brand href="/">
            <img src={Logo} alt='Logo' />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav container">
            <Nav className="me-auto">
              <div className="menus-container">
                <Nav.Link href="#second-section">Nosso app</Nav.Link>
                <Nav.Link href="#third-section">Funcionalidades</Nav.Link>
                <Nav.Link href="#fourth-section">Como instalar</Nav.Link>
                <Nav.Link href="#fifth-section">Acesse o app</Nav.Link>
                <div className="social-networks-container">
                  <div className="social-network">
                    <a href="https://www.facebook.com/telembroapp" target="_blank" rel="noreferrer">
                      <img src={FooterFacebook} alt='social' className=""></img>
                    </a>
                  </div>
                  <div className="social-network">
                    <a href="https://www.instagram.com/telembro.app/" target="_blank" rel="noreferrer">
                      <img src={FooterInstagram} alt='social' className=""></img>
                    </a>
                  </div>
                  <div className="social-network">
                    <a href="https://www.linkedin.com/company/telembro-app/" target="_blank" rel="noreferrer">
                      <img src={FooterLinkedin} alt='social' className=""></img>
                    </a>
                  </div>
                  <div className="social-network">
                    <a href="https://www.youtube.com/channel/UCrriL5dwHWOSOs9ZpvJtgag" target="_blank" rel="noreferrer">
                      <img src={FooterYoutube} alt='social' className=""></img>
                    </a>
                  </div>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse >
        </Container >
      </Navbar >
    </>
  );
}