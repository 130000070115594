import {
  useParams,
  // useHistory
} from 'react-router-dom';
import PageHeader from "../../components/PageHeader";
import AppointmentForm from "../../components/AppointmentForm";
import AppointmentsService from '../../services/AppointmentsService';
import { format } from 'date-fns';
import Config from "../../config/environments/local";
import { localGet } from "../../lib/session";
import {
  useState,
} from 'react';

export default function NewAppointment() {

  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const { appointmentCategoryId } = useParams();
  // const { history } = useHistory();

  // console.log(appointmentCategoryId);
  // console.log(history);

  async function handleSubmit(formData) {

    const [day, month, year] = formData.appointmentDate.split('/');
    const appointmentDate = new Date(+year, month - 1, +day);

    let reminderDate1Date = '';
    let reminderDate2Date = '';
    let reminderDate3Date = '';
    let recurrenceDateDate = '';

    if (formData.reminderDate1) {
      const [day1, month1, year1] = formData.reminderDate1.split('/');
      const reminderDate1 = new Date(+year1, month1 - 1, +day1);
      reminderDate1Date = format(new Date(reminderDate1), 'yyyy-MM-dd');
    }

    if (formData.reminderDate2) {
      const [day2, month2, year2] = formData.reminderDate2.split('/');
      const reminderDate2 = new Date(+year2, month2 - 1, +day2);
      reminderDate2Date = format(new Date(reminderDate2), 'yyyy-MM-dd');
    }

    if (formData.reminderDate3) {
      const [day3, month3, year3] = formData.reminderDate3.split('/');
      const reminderDate3 = new Date(+year3, month3 - 1, +day3);
      reminderDate3Date = format(new Date(reminderDate3), 'yyyy-MM-dd');
    }

    if (formData.appointmentRecurrenceEndDate) {
      const [dayRecurrence, monthRecurrence, yearRecurrence] = formData.appointmentRecurrenceEndDate.split('/');
      const recurrenceDate = new Date(+yearRecurrence, monthRecurrence - 1, +dayRecurrence);
      recurrenceDateDate = format(new Date(recurrenceDate), 'yyyy-MM-dd');
    }

    const formDataToSend = new FormData();

    formDataToSend.append('Observations', (formData.observations ?? ''));
    formDataToSend.append('AppointmentCategoryId', formData.appointmentCategoryId);
    formDataToSend.append('AppointmentTypeId', formData.appointmentTypeId);
    formDataToSend.append('BarCodeNumber', (formData.barCodeNumber ?? ''));
    formDataToSend.append('DocumentValue', (formData.realDocumentValue ?? ''));
    formDataToSend.append('AppointmentTime', (formData.appointmentTime ?? ''));
    formDataToSend.append('AppointmentFiles', formData.appointmentFiles);
    formDataToSend.append('ReminderDate1', reminderDate1Date);
    formDataToSend.append('ReminderDate2', reminderDate2Date);
    formDataToSend.append('ReminderDate3', reminderDate3Date);
    formDataToSend.append('AppointmentDate', format(new Date(appointmentDate), 'yyyy-MM-dd'));
    formDataToSend.append('UserId', sessionObject.userId);

    formDataToSend.append('AppointmentRecurrenceTypeId', (formData.appointmentRecurrenceTypeId ?? ''));
    formDataToSend.append('AppointmentRecurrenceEndTime', (formData.appointmentRecurrenceEndTime ?? ''));
    formDataToSend.append('AppointmentRecurrenceUnity', (formData.appointmentRecurrenceUnity ?? ''));
    formDataToSend.append('AppointmentRecurrenceEndDate', recurrenceDateDate);

    formDataToSend.append('HourAdvanceReminder', (formData.hourAdvanceReminder ?? ''));

    // const createAppointmentResponse = await new AppointmentsService(Config.API_BASE_URL).createAppointmentFormData(formDataToSend);
    await new AppointmentsService(Config.API_BASE_URL).createAppointmentFormData(formDataToSend);

    window.open('/', '_self');
  }

  return (
    <>
      <PageHeader
        title='Novo compromisso'
      />
      <AppointmentForm
        buttonLabel='Cadastrar'
        onSubmit={handleSubmit}
        appointment={{
          Id: 0,
          Name: '',
          AppointmentCategoryId: 0,
          AppointmentDate: '',
        }}
        appointmentCategoryId={appointmentCategoryId}
        viewerUserId={sessionObject.userId}
      />
    </>
  )
};