// import delay from '../../utils/delay';

class HttpClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async get(path) {

    // await delay(500);
    const response = await fetch(`${this.baseUrl}${path}`);

    let body = null;
    const contentType = response.headers.get('Content-Type');
    if (contentType.includes('application/json')) {
      body = await response.json();
    }

    if (response.ok) {
      return body;
    }

    throw new Error(
      body?.error ||
      `${response.status} - ${response.statusText}`
    );
  }

  async post(path, model) {

    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    var url = `${this.baseUrl}${path}`;

    console.log(url);

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(model),
      headers,
    });

    let body = null;
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      body = await response.json();
    }

    if (response.ok) {
      if (body) {
        return body;
      }
      else {
        return true;
      }
    }

    throw new Error(
      `${response.status} - ${response.statusText}`
    );
  }

  async postFormData(path, model) {

    // const headers = new Headers({
    //   'Content-Type': 'multipart/form-data'
    // });

    const response = await fetch(`${this.baseUrl}${path}`, {
      method: 'POST',
      body: model,
      // headers,
    });

    // let body = null;
    // const contentType = response.headers.get('Content-Type');
    // if (contentType.includes('application/json')) {
    //   body = await response.json();
    // }

    if (response.ok) {
      return true;
    }

    throw new Error(
      `${response.status} - ${response.statusText}`
    );
  }
}

export default HttpClient;