import Appointments from "../../components/Appointments"
import {
  Form,
  // ButtonContainer
} from "./styles";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
// import isMatch from 'date-fns/isMatch'
import Config from "../../config/environments/local";
import AppointmentTypesService from '../../services/AppointmentTypesService';
import AppointmentCategoriesService from '../../services/AppointmentCategoriesService';
import { localGet } from "../../lib/session";
import { format } from 'date-fns';

import "react-datepicker/dist/react-datepicker.css";
import
// DatePicker,
Calendar,
{ registerLocale }
  from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('pt-br', ptBR)
// import DatePicker from "react-datepicker";

export default function AllAppointments() {
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const [isLoading, setIsLoading] = useState(false);
  const [appointmentTypeId, setAppointmentTypeId] = useState('');
  // const [startDate, setStartDate] = useState('');
  // const [startDate, setStartDate] = useState(new Date());
  const [startDateDate, setStartDateDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [endDateDate, setEndDateDate] = useState('');
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentCategories, setAppointmentCategories] = useState([]);
  // import useErrors from "../../hooks/useErrors";
  // const {
  //   setError,
  //   removeError,
  // } = useErrors();

  const loadAppointmentTypes = useCallback(async () => {
    try {
      setIsLoading(true);
      const appointmentTypesList = await new AppointmentTypesService(Config.API_BASE_URL).listAppointmentTypesByUserId(sessionObject.userId);
      setAppointmentTypes(appointmentTypesList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject.userId]);

  const loadAppointmentCategories = useCallback(async () => {
    try {
      setIsLoading(true);
      const appointmentCategoriesList = await new AppointmentCategoriesService(Config.API_BASE_URL).listAppointmentCategories();
      setAppointmentCategories(appointmentCategoriesList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  function filterAppointmentTypes(appointmentTypes, appointmentCategoryId) {
    const filteredAppointmentTypes = appointmentTypes.filter((appointmentType) => appointmentType.AppointmentCategoryId === appointmentCategoryId);

    return filteredAppointmentTypes ?? [];
  }

  function getStringDate(date) {
    return format(date, 'yyyy-MM-dd');
  }

  useEffect(() => {
    loadAppointmentTypes();
    loadAppointmentCategories();
  }, [
    loadAppointmentTypes,
    loadAppointmentCategories,
  ]);

  // function handleFilter(e) {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   setIsLoading(true);
  //   setAppointmentTypeId(e.target.value);
  //   setStartDate(e.target.value);
  //   setEndDate(e.target.value);
  // }

  // function handleClearFilters(e) {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   setAppointmentTypeId(null);
  //   setStartDate(null);
  //   setEndDate(null);
  // }

  function handleChangeAppointmentType(e) {
    e.preventDefault();
    e.stopPropagation();

    setAppointmentTypeId(e.target.value);
  }

  // function handleChangeStartDate(e) {
  //   const inputValue = e.target.value;
  //   setStartDate(inputValue);

  //   if (e.target.value.length < 10) {
  //     setError({ field: 'startDate', message: 'Data inválida' });
  //     return false;
  //   }

  //   if (!inputValue) {
  //     setError({ field: 'startDate', message: 'Informe uma data para filtrar' });
  //     return false;
  //   }
  //   else if (inputValue.length === 10 && (!isMatch(inputValue, 'dd/MM/yyyy'))) {
  //     setError({ field: 'startDate', message: 'Data inválida' });
  //     return false;
  //   }
  //   else {
  //     removeError('startDate');
  //   }

  //   try {
  //     const [day, month, year] = inputValue.split('/');
  //     const date = new Date(+year, month - 1, +day);
  //     var today = new Date();
  //     today.setHours(0, 0, 0, 0);

  //     if (date) {
  //       setStartDateDate(date);
  //       removeError('startDate');
  //     }
  //     else {
  //       setStartDateDate(null);
  //     }
  //   }
  //   catch (err) {
  //     setError({ field: 'startDate', message: 'Data inválida' });
  //     return false;
  //   }
  // }

  // function handleChangeEndDate(e) {
  //   const inputValue = e.target.value;
  //   setEndDate(inputValue);

  //   if (e.target.value.length < 10) {
  //     setError({ field: 'endDate', message: 'Data inválida' });
  //     return false;
  //   }

  //   if (!inputValue) {
  //     setError({ field: 'endDate', message: 'Informe uma data para filtrar' });
  //     return false;
  //   }
  //   else if (inputValue.length === 10 && (!isMatch(inputValue, 'dd/MM/yyyy'))) {
  //     setError({ field: 'endDate', message: 'Data inválida' });
  //     return false;
  //   }
  //   else {
  //     removeError('endDate');
  //   }

  //   try {
  //     const [day, month, year] = inputValue.split('/');
  //     const date = new Date(+year, month - 1, +day);
  //     var today = new Date();
  //     today.setHours(0, 0, 0, 0);

  //     if (date) {
  //       setEndDateDate(date);
  //       removeError('endDate');
  //     }
  //     else {
  //       setEndDateDate(null);
  //     }
  //   }
  //   catch (err) {
  //     setError({ field: 'endDate', message: 'Data inválida' });
  //     return false;
  //   }
  // }

  function handleStartDateOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;
    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 2) {
        input.value += '/';
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        input.value += '/';
      }
    }
  }

  function handleEndDateOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;
    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 2) {
        input.value += '/';
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        input.value += '/';
      }
    }
  }

  return (
    <>
      <div className="">
        <Form noValidate>
          <Loader isLoading={isLoading} />
          <div>Tipo</div>
          <div>
            <FormGroup>
              <Select
                value={appointmentTypeId}
                onChange={handleChangeAppointmentType}
                placeholder='Selecione um tipo'
              >
                <option value="">Todos</option>
                {(
                  (appointmentCategories.length > 0) &&
                  (appointmentTypes.length > 0)) && (
                    appointmentCategories.map((appointmentCategory) => (
                      <>
                        <optgroup label={appointmentCategory.Name}></optgroup>
                        {
                          (filterAppointmentTypes(appointmentTypes, appointmentCategory.Id)).map((appointmentType) => (
                            <option value={appointmentType.Id}>{appointmentType.Name}</option>
                          ))
                        }
                      </>
                    ))
                  )}
              </Select>
            </FormGroup>
          </div>

          <div>
            <FormGroup>
              <div className="row">
                <div className="col-md-6">
                  <div>De</div>
                  <div>
                    <Calendar
                      onChange={(date) => setStartDateDate(date)}
                      locale="pt-br"
                      dateFormat="dd/MM/yyyy"
                      selected={startDateDate}
                      placeholder='__/__/____'
                      customInput={
                        <Input
                          type="text"
                          // value={startDateDate}
                          maxLength="10"
                          placeholder='__/__/____'
                          // onChange={handleChangeStartDate}
                          onKeyPress={handleStartDateOnKeyPress}
                          onKeyUp={handleStartDateOnKeyPress}
                        />
                      }
                    />

                    {/* <DatePicker
                      type="date"
                      locale="pt-br"
                      dateFormat="dd/MM/yyyy"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      onKeyPress={handleStartDateOnKeyPress}
                    // onChange={handleChangeStartDate}
                    // value={startDate}
                    /> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div>Até</div>
                  <div>
                    {/* <Input
                      type="text"
                      value={endDate}
                      maxLength="10"
                      placeholder='__/__/____'
                      onChange={handleChangeEndDate}
                      onKeyPress={handleEndDateOnKeyPress}
                      onKeyUp={handleEndDateOnKeyPress}
                    /> */}
                    <Calendar
                      onChange={(date) => setEndDateDate(date)}
                      locale="pt-br"
                      dateFormat="dd/MM/yyyy"
                      selected={endDateDate}
                      placeholder='__/__/____'
                      customInput={
                        <Input
                          type="text"
                          // value={endDateDate}
                          maxLength="10"
                          placeholder='__/__/____'
                          // onChange={handleChangeEndDate}
                          onKeyPress={handleEndDateOnKeyPress}
                          onKeyUp={handleEndDateOnKeyPress}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </FormGroup>
          </div>

          <div>
            <FormGroup>
              <Button
                type='submit'
                onClick={(e) => {
                  window.open('/all', '_self');
                }}
              // onClick={handleClearFilters}
              >
                Limpar filtros
              </Button>

              <Button
                type='submit'
                onClick={(e) => {
                  e.preventDefault();

                  window.open(`${Config.KROONAR_PRINT_URL}?UserId=${sessionObject.userId}&AppointmentTypeId=${appointmentTypeId}&StartDate=${getStringDate(startDateDate)}&EndDate=${getStringDate(endDateDate)}`, '_blank');
                }}
              // onClick={handleClearFilters}
              >
                Gerar relatório de compromissos
              </Button>
            </FormGroup>
          </div>
        </Form>
      </div>
      <Appointments
        fetchOnlyActive={false}
        appointmentTypeId={appointmentTypeId}
        startDate={startDateDate}
        endDate={endDateDate}
      />
    </>
  )
};