import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import { Overlay, Container, Footer } from './styles';
import Button from '../Button';

export default function ModalOptions({ title, body, danger, onCancel, onOption1, onOption2, option1Text, option2Text, isVisible }) {

  function handleCancel() {
    onCancel();
  }

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Overlay>
        <Container danger={danger}>
          <h1>
            {title}
          </h1>
          <p>
            {body}
          </p>

          <Footer>
            <Button
              type='button'
              className='cancel-button'
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              type='button'
              danger={danger}
              onClick={onOption1}
            >
              {option1Text}
            </Button>
            <Button
              type='button'
              danger={danger}
              onClick={onOption2}
            >
              {option2Text}
            </Button>
          </Footer>

        </Container>
      </Overlay>
    </>,
    document.getElementById('modal-root')
  );
}

ModalOptions.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  danger: PropTypes.bool,
  onOption1: PropTypes.func.isRequired,
  onOption2: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  option1Text: PropTypes.string.isRequired,
  option2Text: PropTypes.string.isRequired,
}

ModalOptions.defaultProps = {
  danger: false,
  isVisible: false,
}