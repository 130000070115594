import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import { Overlay, Container, Footer } from './styles';
import Button from '../Button';
import Input from '../Input';
import isEmailValid from '../../utils/isEmailValid';

export default function ModalShareEmail({ title, danger, onConfirm, onCancel, isVisible, emailValue, onChangeEmail }) {

  function handleCancel() {
    onCancel();
  }

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Overlay>
        <Container danger={danger}>
          <h1>
            {title}
          </h1>
          <p>
            <Input
              value={emailValue}
              onChange={onChangeEmail}
            />
          </p>

          <Footer>
            <Button
              type='button'
              className='cancel-button'
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              type='button'
              danger={danger}
              onClick={onConfirm}
              disabled={!isEmailValid(emailValue)}
            >
              Confirmar
            </Button>
          </Footer>

        </Container>
      </Overlay>
    </>,
    document.getElementById('modal-root')
  );
}

ModalShareEmail.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  danger: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  emailValue: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
}

ModalShareEmail.defaultProps = {
  danger: false,
  isVisible: false,
}