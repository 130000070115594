import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Shared from './pages/Shared';
import SharedManagement from './pages/SharedManagement';
import AllAppointments from './pages/AllAppointments';
import Appointments from './pages/Home';
import NewAppointment from './pages/NewAppointment';
import EditAppointment from './pages/EditAppointment';
// import SharedAppointment from './pages/components/SharedAppointment';
import SharedAppointment from './components/SharedAppointment';
import AppointmentTypes from './components/AppointmentTypes';
import NewAppointmentType from './pages/NewAppointmentType';
import EditAppointmentType from './pages/EditAppointmentType';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';
import NewUser from './pages/NewUser';
import EditUser from './pages/EditUser';
import UserConfirmation from './pages/UserConfirmation';

export default function Routes(){
  return (
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route exact path="/forgot-password" component={ForgotPassword}/>
      <Route exact path="/reset-password/:code" component={ResetPassword}/>
      <Route exact path="/change-password" component={ChangePassword}/>
      <Route exact path="/shared" component={Shared}/>
      <Route exact path="/shared-management" component={SharedManagement}/>
      <Route exact path="/shared/:id" component={SharedAppointment}/>
      <Route exact path="/active" component={Home}/>
      <Route exact path="/all" component={AllAppointments}/>
      <Route exact path="/appointments/:viewerUserId" component={Appointments}/>
      <Route exact path="/login" component={Login}/>
      <Route exact path="/new/:appointmentCategoryId" component={NewAppointment}/>
      {/* <Route exact path="/edit/:id" component={EditAppointment}/> */}
      <Route exact path="/edit/:id" component={EditAppointment}/>
      <Route exact path="/" component={Home}/>
      <Route exact path="/users/new" component={NewUser}/>
      <Route exact path="/users/edit/:id" component={EditUser}/>
      <Route exact path="/users/confirm/:code" component={UserConfirmation}/>
      <Route exact path="/appointment-types" component={AppointmentTypes}/>
      <Route exact path="/appointment-types/edit/:id" component={EditAppointmentType}/>
      <Route exact path="/appointment-types/new/:appointmentCategoryId" component={NewAppointmentType}/>
    </Switch>
  );
}