import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { Container, Header } from './styles';
import useErrors from '../../hooks/useErrors';
import { Form, ButtonContainer } from './styles';
// import PageHeader from '../../components/PageHeader';
import FormGroup from '../../components/FormGroup';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Config from '../../config/environments/local';
import UsersService from '../../services/UsersService';
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
  // faDollarSign,
} from '@fortawesome/free-solid-svg-icons'

export default function ResetPassword() {

  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const isFormValid = (password && passwordAgain && (password === passwordAgain));
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPaswordSuccess, setIsResetPaswordSuccess] = useState(false);
  const [serviceError, setServiceError] = useState('');
  const [userId, setUserId] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);
  const { code } = useParams();
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const {
    // errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const getUserByCode = useCallback(async () => {
    if (!hasRequested) {
      try {
        setIsLoading(true);
        setHasRequested(true);
        const response = await new UsersService(Config.API_BASE_URL).GetUserByCode({
          code
        });

        if (response.Error) {
          setError({ field: 'password', message: response.Error.Message });
          setServiceError(response.Error.Message);
        }
        else {
          setUserId(response.Result);
        }

      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [code, setError, hasRequested]);

  // async function handleReload() {
  //   await getUserByCode();
  // };

  useEffect(() => {
    getUserByCode();
  }, [getUserByCode]);

  function isPasswordValid(password) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return regex.test(password);
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);

    if (!e.target.value) {
      setError({ field: 'password', message: 'a senha é obrigatória' });
    }
    else if (!isPasswordValid(e.target.value)) {
      setError({ field: 'password', message: 'a senha deve conter um mínimo de 8 letras, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula e pelo menos um número' });
    }
    else {
      removeError('password');
    }
  }

  function handleChangePasswordAgain(e) {
    setPasswordAgain(e.target.value);

    if (!e.target.value) {
      setError({ field: 'passwordAgain', message: 'digite a mesma senha novamente' });
    } else {

      if (password !== e.target.value) {
        setError({ field: 'passwordAgain', message: 'digite a mesma senha novamente' });
        return;
      }
      else if (!isPasswordValid(e.target.value)) {
        setError({ field: 'passwordAgain', message: 'a senha deve conter um mínimo de 8 letras, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula e pelo menos um número' });
      }
      else {
        removeError('passwordAgain');
      }
    }
  }

  async function handleGoToLogin(e) {
    e.preventDefault();
    e.stopPropagation();

    window.open('/login', '_self');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    const response = await new UsersService(Config.API_BASE_URL).UpdatePassword({
      UserId: userId,
      Password: password,
    });

    if (response.Error) {
      setError({ field: 'password', message: response.Error.Message });
      setServiceError(response.Error.Message);
      setIsResetPaswordSuccess(true);
    } else {
      setIsResetPaswordSuccess(true);
      removeError('password');
      removeError('passwordAgain');
    }

    setIsLoading(false);

  }

  return (
    <>
      <Container>
        <Header>
          <strong>
            Recuperação de senha
          </strong>
        </Header>
      </Container>

      {/* <PageHeader
        title='Recuperação de senha'
      /> */}

      {(!isResetPaswordSuccess) ? (
        <Form onSubmit={handleSubmit} noValidate>
          <div>Informe a nova senha</div>
          <FormGroup
            error={getErrorMessageByFieldName('password')}
          >
            <div className="input-group-btn">
              <Input
                type={passwordType}
                value={password}
                placeholder="Nova senha"
                onChange={handleChangePassword}
                error={getErrorMessageByFieldName('password')}
              />
              <button type="button" className="btn" onClick={togglePassword}>
                {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
              </button>
            </div>
          </FormGroup>

          <div>Digite a sua senha novamente</div>
          <FormGroup
            error={getErrorMessageByFieldName('passwordAgain')}
          >
            <div className="input-group-btn">
              <Input
                type={passwordType}
                value={passwordAgain}
                placeholder="Senha novamente"
                onChange={handleChangePasswordAgain}
                error={getErrorMessageByFieldName('passwordAgain')}
              />
              <button type="button" className="btn" onClick={togglePassword}>
                {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
              </button>
            </div>
          </FormGroup>

          <Loader isLoading={isLoading} />

          <ButtonContainer>
            <Button
              type="button"
              disabled={!isFormValid}
              onClick={(e) => handleSubmit(e)}

            >
              Confirmar
            </Button>
          </ButtonContainer>
        </Form >
      ) : (
        <Container>
          <h4>
            {(serviceError) ? (
              <>
                {serviceError}
              </>
            ) : (
              <>
                Sua senha foi alterada com sucesso.
              </>
            )}
          </h4>

          {(serviceError) ? (
            <ButtonContainer>
              <Button
                type="button"
                onClick={(e) => setIsResetPaswordSuccess(false)}
              >
                Alterar o e-mail e tentar novamente
              </Button>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <Button
                type="button"
                onClick={(e) => handleGoToLogin(e)}
              >
                Realizar login
              </Button>
            </ButtonContainer>
          )}
        </Container>
      )}


    </>
  )
};