import AppointmentTypeForm from "../../components/AppointmentTypeForm";
// import { Container, Header } from './styles';
import AppointmentTypesService from '../../services/AppointmentTypesService';
import Config from '../../config/environments/local';
import PageHeader from "../../components/PageHeader";
// import Loader from '../../components/Loader';
import { useState } from "react";
import { localGet } from "../../lib/session";
import { useParams } from 'react-router-dom';

export default function NewAppointmentType() {

  // const [isLoading, setIsLoading] = useState(true);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const { appointmentCategoryId } = useParams();

  async function handleSubmit(formData) {

    const response = await new AppointmentTypesService(Config.API_BASE_URL).Create({
      Name: formData.name,
      UserId: sessionObject.userId,
      AppointmentCategoryId: appointmentCategoryId,
    });

    return response;
  }

  return (
    <>
      {/* <Loader isLoading={isLoading} /> */}
      <PageHeader
        title='Criar novo tipo'
        urlBack='/appointment-types'
      />
      <AppointmentTypeForm
        onSubmit={handleSubmit}
        appointmentType={{
          Name: ''
        }}
      />
    </>
  )
};