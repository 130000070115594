/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Container,
  Header,
  ListContainer,
  Card,
  ErrorContainer,
  CardsContainer,
  CheckboxContainer,
  CardAndCheckboxContainer,
  CheckboxAllContainer,
} from './styles';
// import Trash from '../../assets/icons/trash.svg';
// import Edit from '../../assets/icons/edit.svg';
import Share from '../../assets/icons/share.svg';
// import ArrowUp from '../../assets/icons/arrow-up.svg';
// import { InputSearchContainer } from '../Header/styles';
import Loader from '../Loader';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { format } from 'date-fns';
import AppointmentsService from '../../services/AppointmentsService';
// import SharedAppointmentsService from '../../services/SharedAppointmentsService';
import Button from '../Button';
import ModalShareEmail from '../ModalShareEmail';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
// import PlusSign from '../../assets/icons/plus-sign.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFolder,
  faCalendarDay,
  faBell,
  faInfoCircle,
  faRecycle,
  // faDollarSign,
} from '@fortawesome/free-solid-svg-icons'
import SharedAppointmentsService from '../../services/SharedAppointmentsService';
// import Checkbox from '../Checkbox';

// // https://fontawesome.com/v5/cheatsheet

export default function SharedAppointments({ fetchOnlyActive }) {
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  // const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(true);
  // const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  // const [appointmentToShare, setAppointmentToShare] = useState(null);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedAppointments, setCheckedAppointments] = useState([]);
  const [sharedEmail, setSharedEmail] = useState('');

  const listAppointments = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentsList = await new AppointmentsService(Config.API_BASE_URL).listAppointmentsNotShared(sessionObject.userId, fetchOnlyActive);
      setAppointments(appointmentsList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject.userId, fetchOnlyActive]);

  function handleChangeEmail(e) {
    setSharedEmail(e.target.value);
  }

  async function handleReload() {
    await listAppointments();
  };

  useEffect(() => {
    listAppointments();
  }, [listAppointments]);

  async function handleOpenShareModal(e) {
    setIsShareModalOpen(true);
  }

  async function handleShare(e) {
    setIsShareModalOpen(true);

    const ShareAppointmentsResponse = await new SharedAppointmentsService(Config.API_BASE_URL).shareAppointments({
      AppointmentsIds: checkedAppointments,
      SharedUserEmail: sharedEmail,
    });

    console.log(ShareAppointmentsResponse);

    if (ShareAppointmentsResponse.Error) {
      alert("Ocorreu um erro ao compartilhar");
      console.log('Ocorreu um erro ao compartilhar');
    }
    else {
      console.log(ShareAppointmentsResponse.Error);
      alert("Compromissos compartilhados com sucesso!");
      window.open('/', '_self');
    }
    // window.open(`/shared/${appointmentToShare.Id}`, '_self');

    // await handleReload(a);
    setIsShareModalOpen(false);
  }

  function handleCloseShareModal(e, appointment) {
    setIsShareModalOpen(false);
  }

  function handleCheckboxChange(appointmentId) {
    const appointmentAlreadyExists = checkedAppointments.find((checkedAppointmentId) => checkedAppointmentId === appointmentId);

    if (appointmentAlreadyExists) {
      //remove o appointment
      setCheckedAppointments((prevState) => prevState.filter(
        (checkedAppointmentId) => checkedAppointmentId !== appointmentId
      ));
    }
    else {
      //adiciona o appointment
      setCheckedAppointments((prevState) => [
        ...prevState,
        appointmentId
      ]);
    }
  }

  function handleCheckAllCheckboxex(appointmentId, isChecked) {
    const appointmentAlreadyExists = checkedAppointments.find((checkedAppointmentId) => checkedAppointmentId === appointmentId);

    if (isChecked) {
      if (!appointmentAlreadyExists) {
        //adiciona o appointment
        setCheckedAppointments((prevState) => [
          ...prevState,
          appointmentId
        ]);
      }
    }
    else {
      //remove o appointment
      setCheckedAppointments((prevState) => prevState.filter(
        (checkedAppointmentId) => checkedAppointmentId !== appointmentId
      ));
    }
  }

  function handleIsAllChecked(e) {
    setIsAllChecked(e.target.checked);

    appointments.map((appointment) => handleCheckAllCheckboxex(appointment.Id, e.target.checked));
  };

  return (
    <Container>
      <Loader isLoading={isLoading} />

      <ModalShareEmail
        title='E-mail'
        isVisible={isShareModalOpen}
        onConfirm={handleShare}
        onCancel={handleCloseShareModal}
        emailValue={sharedEmail}
        onChangeEmail={handleChangeEmail}
      />

      <Header>
        <strong>
          {appointments.length}
          {appointments.length === 1 ? ' compromisso' : ' compromissos'}
        </strong>
        {/* <Link to='/new'>Novo compromisso</Link> */}
        {
          (checkedAppointments.length > 0) && (
            <a
              href='#'
              onClick={handleOpenShareModal}
              onKeyDown={handleOpenShareModal}
            >
              <img src={Share} alt='Share' />&nbsp;&nbsp;
              Compartilhar {checkedAppointments.length}
            </a>
          )
        }
      </Header>
      <ListContainer>
        {/* <header>
          <button type='button' className='sort-button'>
            <span>Nome <img src={ArrowUp} alt='Arrow Up' /></span>
          </button>
        </header> */}
      </ListContainer>

      {hasError && (
        <ErrorContainer>
          <strong>
            Ocorreu um erro ao retornar os compromissos
          </strong>
          <Button
            type="button"
            onClick={(e) => { handleReload(); }}
          >
            Tentar novamente
          </Button>
        </ErrorContainer>
      )}

      {!hasError && (
        <>
          {/* <ShareContainer>
            <div>
              <div className='info'>
                <label>
                  E-mail
                </label>
                <Input
                />
              </div>
              <div className='actions'>
                <Button
                  type="button"
                  onClick={(e) => { handleShare(e); }}
                >
                  Confirmar
                </Button>
                <Button
                  type="button"
                  onClick={(e) => { handleShare(e); }}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </ShareContainer> */}
          <CheckboxAllContainer>
            {/* <Checkbox
                      key={appointment.Id}
                    /> */}
            <label>
              <input
                type="checkbox"
                checked={isAllChecked}
                onChange={handleIsAllChecked}
              />
              Marcar todos
            </label>
          </CheckboxAllContainer>
          <CardsContainer>
            {appointments && (
              appointments.map((appointment) => (
                <CardAndCheckboxContainer>
                  <CheckboxContainer>
                    <label>
                      <input
                        type="checkbox"
                        key={appointment.Id}
                        checked={(checkedAppointments.includes(appointment.Id))}
                        onChange={(e) => {
                          handleCheckboxChange(appointment.Id);
                        }}
                      />
                    </label>
                  </CheckboxContainer>
                  <Card
                    key={`id-${appointment.Id}`}
                  >
                    <div className="info">
                      <div className="appointment-name">
                        <strong>{appointment.AppointmentCategoryName}</strong>
                        {/* <small>Importante</small> */}
                      </div>

                      <div className="span">
                        <FontAwesomeIcon icon={faFolder} />&nbsp;&nbsp;
                        {appointment.AppointmentTypeName}
                      </div>

                      <div className="span">
                        <FontAwesomeIcon icon={faCalendarDay} />&nbsp;&nbsp;
                        <b>
                          {format(new Date(appointment.AppointmentDate), 'dd/MM/yyyy')}

                          {appointment.AppointmentTime && (
                            ` - ${appointment.AppointmentTime}`
                          )}
                        </b>
                      </div>

                      {appointment.ReminderDates && (
                        <div className="span">
                          {
                            appointment.ReminderDates[0] && (
                              <>
                                <FontAwesomeIcon icon={faBell} />&nbsp;&nbsp;
                                {format(new Date(appointment.ReminderDates[0]), 'dd/MM/yyyy')}
                              </>
                            )
                          }

                          {
                            appointment.ReminderDates[1] && (
                              <>
                                , {format(new Date(appointment.ReminderDates[1]), 'dd/MM/yyyy')}
                              </>
                            )
                          }

                          {
                            appointment.ReminderDates[2] && (
                              <>
                                &nbsp;e {format(new Date(appointment.ReminderDates[2]), 'dd/MM/yyyy')}
                              </>
                            )
                          }

                        </div>
                      )}

                      {
                        (appointment.DocumentValue !== 0) && (appointment.DocumentValue) && (
                          <>
                            <div className="span">
                              {appointment.DocumentValuestring}
                            </div>
                          </>
                        )
                      }

                      {appointment.Observations && (
                        <div className="span">
                          <FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;
                          {appointment.Observations}
                        </div>
                      )}

                      {appointment.AppointmentHasRecurrences && (
                        <div className="span">
                          <FontAwesomeIcon icon={faRecycle} />&nbsp;&nbsp;
                          Compromisso recorrente
                        </div>
                      )}

                    </div>
                  </Card>
                </CardAndCheckboxContainer>

              ))
            )}
          </CardsContainer>
        </>
      )}
    </Container >
  );
}