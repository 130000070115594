import LoginForm from "../../components/LoginForm";
import { Container, Header } from './styles';
// import Squares from '../../assets/images/new-layout/squares.svg';
// import Logo from '../../assets/images/logo.svg';

export default function Login() {
  return (
    <>
      <Container>
        <Header>
          {/* <strong>
            Entrar
          </strong> */}
          {/* <div>
            <img src={Squares} alt='Squares'></img>
          </div> */}
        </Header>
        {/* <Header>
          <div>
            <img src={Logo} alt='Squares'></img>
          </div>
        </Header> */}
      </Container>

      <LoginForm
      />
    </>
  )
};