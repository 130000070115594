const Config = {
  // API_BASE_URL: 'https://telembro.service.kroonar.com/api',
  API_BASE_URL: 'https://homol.telembro.service.kroonar.com/api',
  // API_BASE_URL: 'http://localhost:56320/api',
  // KROONAR_PRINT_URL: 'https://localhost:44309/Print/TeLembro',
  KROONAR_PRINT_URL: 'https://print.kroonar.com/Print/TeLembro',
  SESSION_KEY: 'telembro_session',
  EXPIRATION_AUTH: process.env.MAX_IDLE_TIME || 2.592e+9,
  DEFAULT_USER_OBJECT: {
    id: 1,
    name: 'Márcio Alvim',
    password: '123456'
  }
};

export default Config;