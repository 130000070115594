// import appointments from "../../components/appointments"
// import Splash from "../../components/Splash"
import Calendar from "../../assets/images/calendar.svg";
import Availability from "../../assets/images/availability.svg";
// import PrintScreens from "../../assets/images/print-screens.svg";
import Print1 from "../../assets/images/carosel/1.svg";
import Print2 from "../../assets/images/carosel/2.svg";
import Print3 from "../../assets/images/carosel/3.svg";
import Print4 from "../../assets/images/carosel/4.svg";
import Print5 from "../../assets/images/carosel/5.svg";
// import Print6 from "../../assets/images/carosel/6.png";
import CalendarIcon from "../../assets/icons/Calendar-icon.svg";
import ClockIcon from "../../assets/icons/Clock-icon.svg";
import Tutorial from "../../assets/images/tutorial.svg";
import AvailabileBanner from "../../assets/images/available-banner.svg";
import AvailabileBannerMobile from "../../assets/images/acesseoapp.svg";
import Facebook from "../../assets/icons/facebook-f-brands.svg";
import Instagram from "../../assets/icons/instagram-brands.svg";
import Linkedin from "../../assets/icons/linkedin-in-brands.svg";
import Youtube from "../../assets/icons/youtube-brands.svg";
import Logo from '../../assets/images/logotipo.svg';
import FooterFacebook from "../../assets/icons/footer-facebook-f-brands.svg";
import FooterInstagram from "../../assets/icons/footer-instagram-brands.svg";
import FooterLinkedin from "../../assets/icons/footer-linkedin-in-brands.svg";
import FooterYoutube from "../../assets/icons/footer-youtube-brands.svg";
import Slider from "react-slick";
// import React, { useEffect, useState } from "react";


export default function Home() {

  // const [width, setWidth] = useState(window.innerWidth);
  // const [isMobile, setIsMobile] = useState(width < 767);

  // useEffect(() => {
  //   function handleResize() {
  //     setWidth(window.innerWidth);
  //   }
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [width]);

  // useEffect(() => {
  //   width < 767 && handleSideNavToggle();
  // }, [width]);

  // function handleSideNavToggle() {
  //   console.log("toggle it");
  //   setIsMobile(true);
  // }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <>
      {/* <Splash
        isVisible={true}
      /> */}

      {/* <appointments
        fetchOnlyActive={true}
      /> */}
      <div className="first-section">
        <div id="first-section"></div>
        <div className="container-left">
          <div className="text dark">
            É importante?
            <span className="text orange">
              Nós TeLembramos!
            </span>
          </div>
          <div className="text orange">
            Na sua casa, na rua, no escritório,
          </div>
          <div className="text dark">
            em qualquer lugar.
          </div>

          <div className="button-container">
            {/* <button
              onClick={() => {
                window.open("https://telembroapp.com/", "_new")
              }}
            >
              Como instalar
            </button> */}

            <a href="#fourth-section" rel="noreferrer">
              Como instalar
            </a>

            <button
              onClick={() => {
                window.open("https://telembroapp.com/", "_new")
              }}
            >
              Acesse o app
            </button>
          </div>

          <div className="availability-container">
            <div>
              <img src={Availability} alt='availability' className="availability"></img>
            </div>
          </div>
        </div>

        <img src={Calendar} alt='calendar' className="calendar"></img>
      </div>

      <div className="second-section">
        <div id="second-section"></div>
        <div className="title-container">
          Conheça Nosso App
        </div>
        <div className="carousel">
          <Slider {...settings}>
            <div>
              <img src={Print1} alt='print' className="print-screens"></img>
            </div>
            <div>
              <img src={Print2} alt='print' className="print-screens"></img>
            </div>
            <div>
              <img src={Print3} alt='print' className="print-screens"></img>
            </div>
            <div>
              <img src={Print4} alt='print' className="print-screens"></img>
            </div>
            <div>
              <img src={Print5} alt='print' className="print-screens"></img>
            </div>
            {/* <div>
              <img src={Print6} alt='print' className="print-screens"></img>
            </div> */}
          </Slider>
        </div>
        <div id="third-section"></div>
        <div className="title-container dark">
          Nossas Funcionalidades
        </div>
        <div className="smaller-container">
          <div className="functionalities-container">
            <div className="functionality">
              <div className="top">
                <div className="image">
                  <img src={CalendarIcon} alt='calendar' className=""></img>
                </div>
                <div className="title">
                  Datas de vencimento de documentos
                </div>
              </div>
              <div className="bottom">
                Arquivamento de datas de vencimento de qualquer documento (passaporte, visto, carteira de motorista etc.)
              </div>
            </div>
            <div className="functionality">
              <div className="top">
                <div className="image">
                  <img src={ClockIcon} alt='calendar' className=""></img>
                </div>
                <div className="title">
                  Datas de vencimento de contratos
                </div>
              </div>
              <div className="bottom">
                Arquivamento de datas de vencimento de qualquer contrato, em especial aqueles com fidelidade e que tenham renovações automáticas
              </div>
            </div>
            <div className="functionality">
              <div className="top">
                <div className="image">
                  <img src={CalendarIcon} alt='calendar' className=""></img>
                </div>
                <div className="title">
                  Datas de vencimento de boletos
                </div>
              </div>
              <div className="bottom">
                Arquivamento de datas de vencimento de qualquer tipo de documento/compromisso de pagamento
              </div>
            </div>
            <div className="functionality">
              <div className="top">
                <div className="image">
                  <img src={ClockIcon} alt='calendar' className=""></img>
                </div>
                <div className="title">
                  Compartilhamento de compromissos
                </div>
              </div>
              <div className="bottom">
                Arquivamento de contas em comum de um casal / uma família (por meio da funcionalidade de compartilhamento)
              </div>
            </div>
          </div>
          <div className="big-text">
            {/* [Márcio, este texto era só um exemplo. Você sugerirá um novo, ou podemos remover esta seção?] */}
          </div>
          <div className="button-container">
            <button
              onClick={() => {
                window.open("https://telembroapp.com/", "_new")
              }}
            >
              Acesse o app
            </button>
          </div>
        </div>
      </div>

      <div className="third-section">
        <div id="fourth-section"></div>
        <div className="title-container">
          Como instalar
        </div>
        <div className="smaller-container">
          <div className="tutorial-container">
            <div className="tutorial-row">
              <div className="tutorial half-left">
                <div className="left">
                  <div className="image">
                    <img src={Tutorial} alt='calendar' className=""></img>
                  </div>
                </div>
                <div className="right">
                  <div className="title">
                    Como instalar no IOS
                  </div>
                  <div className="text">
                    Clique no botão abaixo e siga os passos para instalar o app em seu dispositivo. É super simples!
                  </div>
                  <div className="button-container">
                    <button
                      onClick={() => {
                        window.open("https://youtu.be/OOf6pseVuXQ", "_new")
                      }}
                    >
                      Instalação IOS (Vídeo)
                    </button>
                    <button
                      onClick={() => {
                        window.open("https://kroonar.s3.amazonaws.com/Tutorial+iOS.pdf", "_new")
                      }}
                    >
                      Instalação IOS (PDF)
                    </button>
                  </div>
                </div>
              </div>
              <div className="tutorial">
                <div className="left">
                  <div className="image">
                    <img src={Tutorial} alt='calendar' className=""></img>
                  </div>
                </div>
                <div className="right">
                  <div className="title">
                    Como instalar no Android
                  </div>
                  <div className="text">
                    Clique no botão abaixo e siga os passos para instalar o app em seu dispositivo. É super simples!
                  </div>
                  <div className="button-container">
                    <button
                      onClick={() => {
                        window.open("https://youtu.be/gtdKYCJ_BgE", "_new")
                      }}
                    >
                      Instalação Android (Vídeo)
                    </button>
                    <button
                      onClick={() => {
                        window.open("https://kroonar.s3.amazonaws.com/Tutorial+Android.pdf", "_new")
                      }}
                    >
                      Instalação Android (PDF)
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="tutorial-row">
              <div className="tutorial whole">
                <div className="left">
                  <div className="image">
                    <img src={Tutorial} alt='calendar' className=""></img>
                  </div>
                </div>
                <div className="right">
                  <div className="title">
                    Como instalar no Computador
                  </div>
                  <div className="text">
                    Clique no botão abaixo e siga os passos para instalar o app em seu computador. É super simples!
                  </div>
                  <div className="button-container">
                    <button
                      onClick={() => {
                        window.open("https://kroonar.s3.amazonaws.com/Tutorial+Desktop.pdf", "_new")
                      }}
                    >
                      Instalação no Computador
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fourth-section">
        <div id="fifth-section"></div>
        <a href="https://telembroapp.com/" target="_blank" rel="noreferrer">
          <img src={AvailabileBanner} alt='acesse' className="desktop"></img>
          <img src={AvailabileBannerMobile} alt='acesse' className="mobile"></img>
        </a>
      </div>


      <div className="fifth-section">
        <div id="talk-tous"></div>
        <div className="title-container">
          Fale Conosco
        </div>
        <div className="smaller-container">
          <div className="text">
            A equipe do aplicativo TeLembro® está aqui para ajudar você, usuário, a aproveitar ao máximo a experiência que o aplicativo pode oferecer.
          </div>
          <div className="text">
            Temos o objetivo de te ajudar a organizar a sua vida de maneira bem simples e segura, portanto, nos empenhamos ao máximo para garantir que a experiência seja a melhor possível.
          </div>
          <div className="text">
            Ao entrar em contato conosco, ficaremos felizes em receber as suas sugestões e perguntas, e nos esforçaremos ao máximo para atendê-las.
          </div>
          <div className="button-container">
            <button
              // onClick={() => {
              //   window.open("https://telembroapp.com/", "_new")
              // }}
              onClick={() => window.location.href = "mailto:info@telembro.com"}
              onKeyDown={() => window.location.href = "mailto:info@telembro.com"}
            >
              Fale conosco
            </button>
          </div>
          <div className="text">
            Desde já agradecemos o seu interesse no aplicativo TeLembro® e nos colocamos à disposição para aumentar o grau de satisfação e oferecer uma experiência incrível de organização e produtividade.
          </div>
          <div className="text">
            Atenciosamente,
          </div>
          <div className="text">
            Equipe de Suporte do TeLembro
          </div>
          <div className="social-networks-container">
            <div className="social-network">
              <a href="https://www.facebook.com/telembroapp" target="_blank" rel="noreferrer">
                <img src={Facebook} alt='social' className=""></img>
              </a>
            </div>
            <div className="social-network">
              <a href="https://www.instagram.com/telembro.app/" target="_blank" rel="noreferrer">
                <img src={Instagram} alt='social' className=""></img>
              </a>
            </div>
            <div className="social-network">
              <a href="https://www.linkedin.com/company/telembro-app/" target="_blank" rel="noreferrer">
                <img src={Linkedin} alt='social' className=""></img>
              </a>
            </div>
            <div className="social-network">
              <a href="https://www.youtube.com/channel/UCrriL5dwHWOSOs9ZpvJtgag" target="_blank" rel="noreferrer">
                <img src={Youtube} alt='social' className=""></img>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="top-container">
          <div className="logo-container">
            <img src={Logo} alt='logo' className=""></img>
          </div>
          <div className="text-container">
            Em casa <br />
            na rua <br />
            no escritório
          </div>
        </div>
        <div className="middle-container">
          <div className="links-container">
            <div className="link-container">
              <a href="#second-section" rel="noreferrer">
                Nosso app
              </a>
            </div>
            <div className="link-container">
              <a href="#third-section" rel="noreferrer">
                Funcionalidades
              </a>
            </div>
            <div className="link-container">
              <a href="#fourth-section" rel="noreferrer">
                Como instalar
              </a>
            </div>
            <div className="link-container">
              <a href="#talk-tous" rel="noreferrer">
                Fale Conosco
              </a>
            </div>
            <div className="link-container">
              <a href="#fifth-section" rel="noreferrer">
                Acesse o app
              </a>
            </div>
          </div>
        </div>
        <div className="bottom-container">
          <div className="social-networks-container">
            <div className="social-network">
              <a href="https://www.facebook.com/telembroapp" target="_blank" rel="noreferrer">
                <img src={FooterFacebook} alt='social' className=""></img>
              </a>
            </div>
            <div className="social-network">
              <a href="https://www.instagram.com/telembro.app/ " target="_blank" rel="noreferrer">
                <img src={FooterInstagram} alt='social' className=""></img>
              </a>
            </div>
            <div className="social-network">
              <a href="https://www.linkedin.com/company/telembro-app/" target="_blank" rel="noreferrer">
                <img src={FooterLinkedin} alt='social' className=""></img>
              </a>
            </div>
            <div className="social-network">
              <a href="https://www.youtube.com/channel/UCrriL5dwHWOSOs9ZpvJtgag " target="_blank" rel="noreferrer">
                <img src={FooterYoutube} alt='social' className=""></img>
              </a>
            </div>
          </div>
        </div>
        <div className="last-container">
          <div className="date-company">
            © {(new Date().getFullYear())} TeLembro
          </div>
        </div>
      </div>
    </>
  )
};