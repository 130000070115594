import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-bottom: 16px;
  position: relative;

  .form-label{
    position: absolute;
    top: 6px;
    left: 16px;
    color: #999;
    font-size: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
    z-index: 1;
  }

  .anchor-container{
    text-align: center;
    a{
      font-size: 14px;
      color: #F25C05;
      text-decoration: none;
    }
  }

  &.complex {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */

    .group {
      input{
        /* max-width: 250px; */
        max-width: 90%;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.recurrencenit{
        input{
        /* max-width: 250px; */
          max-width: 100px !important;
        }
        display: flex !important;
        align-items: flex-start !important;
        justify-content: space-evenly !important;

        button{
          font-size: 24px !important;
        }
      }
    }
    
    button{
      background: transparent;
      border: none;
      margin-left: 8px;
      cursor: pointer;

      &.counter{
        background: ${({ theme }) => theme.colors.primary.main};
        color: #FFF;
        box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
        height: 52px;
        border: 2px solid #FFF;
        border-radius: 4px;
        outline: none;
        padding: 0 16px;
        font-size: 16px;
        transition: background all 0.2s ease-in;
        cursor: pointer;

        &:hover{
          background: ${({ theme }) => theme.colors.primary.light};
        }

        &:active{
          background: ${({ theme }) => theme.colors.primary.dark};
        }
      }
    }
  }

  &.complexcounter {
    .group {
      width: 60%;
      margin: 0;
      display: flex;
      align-items: flex-start;
      text-align: left;

      input{
        max-width: 35%;
        text-align: center;
      }
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &.recurrencenit{
        input{
        /* max-width: 250px; */
          max-width: 100px !important;
        }
        

        button{
          font-size: 24px !important;
        }
      }
    }
    
    button{
      width:24%;
      background: ${({ theme }) => theme.colors.primary.main};
      color: #FFF;
      box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
      height: 52px;
      border: 2px solid #FFF;
      border-radius: 4px;
      outline: none;
      padding: 0 16px;
      font-size: 16px;
      transition: background all 0.2s ease-in;
      cursor: pointer;

      &:hover{
        background: ${({ theme }) => theme.colors.primary.light};
      }

      &:active{
        background: ${({ theme }) => theme.colors.primary.dark};
      }
    }
  }

  small{
    margin-top: 8px;
    font-size: 12px;
    display: block;
    color: ${({ theme }) => theme.colors.danger.main};
  }
  
  & + &{
    margin-top: 16px;
  }
`;