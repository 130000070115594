import { Form, ButtonContainer } from "./styles";
import FormGroup from "../FormGroup";
import Input from "../../components/Input";
import RadioButton from "../../components/RadioButton";
// import RadioButtonLabel from "../../components/RadioButtonLabel";
import Button from "../../components/Button";
import Trash from '../../assets/icons/trash.svg';
// import PropTypes from 'prop-types';
import {
  useState,
  useEffect,
  useCallback,
} from "react";
import useErrors from "../../hooks/useErrors";
// import AppointmentCategoriesService from '../../services/AppointmentCategoriesService';
import AppointmentTypesService from '../../services/AppointmentTypesService';
import AppointmentRecurrenceTypesService from '../../services/AppointmentRecurrenceTypesService';
import AppointmentsService from '../../services/AppointmentsService';
import Loader from '../Loader';
import Modal from '../Modal';
import ModalOptions from '../ModalOptions';
import isMatch from 'date-fns/isMatch'
import Config from "../../config/environments/local";
// import CurrencyInput from 'react-currency-input-field';
import MyCustomNumberFormat from '../MyCustomNumberFormat'
import CreatableSelect from 'react-select/creatable';
import Select from "../../components/Select";

import "react-datepicker/dist/react-datepicker.css";
import
// DatePicker,
Calendar,
{ registerLocale }
  from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('pt-br', ptBR)

export default function AppointmentForm({
  buttonLabel,
  onSubmit,
  appointment,
  onRemoveAttachment,
  appointmentCategoryId,
  viewerUserId,
}) {

  const formatDocumentValue = (numStr) => {
    if (!Number(numStr)) return "";
    return (numStr * 100);
  };

  const isFieldsDisabled = (viewerUserId && ((appointment.Id) && (appointment.UserId !== parseInt(viewerUserId))));
  const [observations, setObservations] = useState(appointment.Observations);
  const [appointmentTypeId, setAppointmentTypeId] = useState(appointment.AppointmentTypeId);
  const [appointmentDate, setAppointmentDate] = useState(appointment.AppointmentDate);
  const [appointmentDateDate, setAppointmentDateDate] = useState(getAppointmentDate(appointmentDate));
  const [barCodeNumber, setBarCodeNumber] = useState(appointment.BarCodeNumber);
  const [documentValue, setDocumentValue] = useState(formatDocumentValue(appointment.DocumentValue));
  const [realDocumentValue, setRealDocumentValue] = useState(appointment.DocumentValue ?? 0);
  const [appointmentTime, setAppointmentTime] = useState(appointment.AppointmentTime);
  const [appointmentRecurrenceId] = useState(appointment.AppointmentRecurrenceId);
  const [appointmentRecurrenceTypeId, setAppointmentRecurrenceTypeId] = useState(appointment.AppointmentRecurrenceTypeId);

  const [appointmentRecurrenceEndDate, setAppointmentRecurrenceEndDate] = useState(appointment.AppointmentRecurrenceEndDate);
  const [appointmentRecurrenceEndDateDate, setAppointmentRecurrenceEndDateDate] = useState(getAppointmentDate(appointmentRecurrenceEndDate));;
  const [appointmentRecurrenceEndTime, setAppointmentRecurrenceEndTime] = useState(appointment.AppointmentRecurrenceEndTime);
  const [appointmentRecurrenceUnity, setAppointmentRecurrenceUnity] = useState(appointment.AppointmentRecurrenceUnity);

  const [hourAdvanceReminder, setHourAdvanceReminder] = useState(appointment.HourAdvanceReminder);
  const [appointmentHasHourAdvanceReminder, setAppointmentHasHourAdvanceReminder] = useState((appointment && appointment.HourAdvanceReminder));
  const [mustShowHourAdvanceReminderSwitch, setMustShowHourAdvanceReminderSwitch] = useState((appointment && appointment.AppointmentTime));

  const [appointmentHasRecurrence, setAppointmentHasRecurrence] = useState((appointment && appointment.AppointmentHasRecurrences));
  const [reminderDate1, setReminderDate1] = useState((appointment && appointment.ReminderDates ? appointment.ReminderDates[0] : null));
  const [reminderDate1Date, setReminderDate1Date] = useState(getReminderDate1(reminderDate1));
  const [reminderDate2, setReminderDate2] = useState((appointment && appointment.ReminderDates ? appointment.ReminderDates[1] : null));
  const [reminderDate2Date, setReminderDate2Date] = useState(getReminderDate2(reminderDate2));
  const [reminderDate3, setReminderDate3] = useState((appointment && appointment.ReminderDates ? appointment.ReminderDates[2] : null));
  const [reminderDate3Date, setReminderDate3Date] = useState(null);

  const [showReminderDate2, setShowReminderDate2] = useState(reminderDate2 ?? false);
  const [showReminderDate3, setShowReminderDate3] = useState(reminderDate3 ?? false);

  const createOption = (id, label) => (id ? {
    label,
    value: id,
  } : null);

  const [showRecurrenceEndTime, setShowRecurrenceEndTime] = useState((appointment && appointment.AppointmentRecurrenceEndTime && ((appointmentCategoryId === '6' || appointmentCategoryId === 6))));
  const [appointmentFiles, setAppointmentFiles] = useState(null);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentRecurrenceTypes, setAppointmentRecurrenceTypes] = useState([]);
  const [mustShowAttachmentFileInput, setMustShowAttachmentFileInput] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  const [appointmentTypeOption, setAppointmentTypeOption] = useState(createOption(appointment.AppointmentTypeId, appointment.AppointmentTypeName));

  const mustShowDocumentValue = (appointmentCategoryId === '5' || appointmentCategoryId === 5);
  const mustShowBarCodeNumber = (appointmentCategoryId === '5' || appointmentCategoryId === 5);
  const mustShowAppointmentTime = (appointmentCategoryId === '6' || appointmentCategoryId === 6);
  const invalidReminderDateLesserThanMessage = 'Esta data não pode ser inferior ou igual à data atual e/ou igual à data do compromisso ou dos outros lembretes';
  const invalidAppointmentDateLesserThanReminderDateMessage = 'Esta data não pode ser inferior ou igual às datas dos lembretes';
  const invalidReminderDateAfteAppointmentDateMessage = 'Esta data não pode ser igual ou superior à data do compromisso ou dos outros lembretes';
  const invalidDateLesserThanTodayMessage = 'Esta data não pode ser inferior ou igual à data atual';
  const mustShowAppointmentRecurrenceOptions = ((appointmentCategoryId === '6' || appointmentCategoryId === 6) || (appointmentCategoryId === '5' || appointmentCategoryId === 5));

  const areRecurrenceFieldsDisabled = (appointment.Id && ((appointmentCategoryId === '6' || appointmentCategoryId === 6) ||
    (appointmentCategoryId === '5' || appointmentCategoryId === 5)));

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = function () {
    let formIsValid = (appointmentTypeId && (appointmentDate && appointmentDate.length === 10) && errors.length === 0) ?? false;

    if (appointmentCategoryId === '5' || appointmentCategoryId === 5) {
      formIsValid = ((documentValue && documentValue.length !== 0 && parseFloat(documentValue) !== 0) && errors.length === 0) && formIsValid;
    }

    if (appointmentHasRecurrence) {
      formIsValid = ((appointmentRecurrenceTypeId && appointmentRecurrenceEndDate && appointmentRecurrenceUnity) && errors.length === 0) && formIsValid;

      if (appointmentRecurrenceTypeId === '1' || appointmentRecurrenceTypeId === 1) {
        formIsValid = ((appointmentTime) && (appointmentRecurrenceEndTime) && errors.length === 0) && formIsValid;
      }
    }

    if (isFieldsDisabled) {
      formIsValid = !isFieldsDisabled;
    }

    return formIsValid;
  }

  async function handleCreateAppointmentType(inputValue) {
    setIsLoading(true);

    const newAppointmentType =
      await new AppointmentTypesService(Config.API_BASE_URL).addAppointmentType({
        Name: inputValue,
        AppointmentCategoryId: appointmentCategoryId,
        UserId: viewerUserId,
      });

    if (newAppointmentType.Result) {
      const createdOption = createOption(newAppointmentType.Result.Id, newAppointmentType.Result.Name);

      setAppointmentTypes((prev) => [...prev, newAppointmentType.Result]);
      setAppointmentTypeOption(createdOption);
      setAppointmentTypeId(createdOption.value);
      setIsLoading(false);
    } else if (newAppointmentType.Error) {
      setError({ field: 'appointmentTypeId', message: 'Opção já existe' });
    }
  };

  if (!appointment.AppointmentFileUrl && !mustShowAttachmentFileInput) {
    setMustShowAttachmentFileInput(true);
  }

  function getAppointmentDate(appointmentDate) {
    if (appointmentDate) {
      const [day, month, year] = appointmentDate.split('/');
      const date = new Date(+year, month - 1, +day);

      if (date) {
        return date;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  function getReminderDate1(reminderDate1) {
    if (reminderDate1) {
      const [day, month, year] = reminderDate1.split('/');
      const date = new Date(+year, month - 1, +day);

      if (date) {
        return date;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  function getReminderDate2(reminderDate2) {
    if (reminderDate2) {
      const [day, month, year] = reminderDate2.split('/');
      const date = new Date(+year, month - 1, +day);

      if (date) {
        return date;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const loadAppointmentTypes = useCallback(async () => {
    try {
      setIsLoading(true);
      const appointmentTypesList = await new AppointmentTypesService(Config.API_BASE_URL).listAppointmentTypesByCategoryId(appointmentCategoryId, viewerUserId);
      setAppointmentTypes(appointmentTypesList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [appointmentCategoryId, viewerUserId]);

  useEffect(() => {
    loadAppointmentTypes();
    async function loadAppointmentRecurrenceTypes() {
      try {
        setIsLoading(true);
        const appointmentRecurrenceTypesList = await new AppointmentRecurrenceTypesService(Config.API_BASE_URL).listAppointmentRecurrenceTypes();
        setAppointmentRecurrenceTypes(appointmentRecurrenceTypesList.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }

    loadAppointmentRecurrenceTypes();

  }, [
    appointmentCategoryId,
    loadAppointmentTypes,
  ]);

  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (mustShowDocumentValue) {
      if ((!documentValue) || (documentValue.length === 0)) {
        setError({ field: 'documentValue', message: 'O valor é obrigatório' });
        return false;
      }
      else {
        removeError('documentValue');
      }
    }


    setIsLoading(true);

    onSubmit({
      observations,
      appointmentFiles,
      appointmentDate,
      appointmentCategoryId,
      appointmentTypeId,
      barCodeNumber,
      realDocumentValue,
      appointmentTime,
      reminderDate1,
      reminderDate2,
      reminderDate3,
      appointmentRecurrenceId,
      appointmentRecurrenceTypeId,
      appointmentRecurrenceEndDate,
      appointmentRecurrenceEndTime,
      appointmentRecurrenceUnity,
      hourAdvanceReminder,
    });

  }

  function handleChangeObservations(e) {
    setObservations(e.target.value);

    if (!e.target.value) {
      setError({ field: 'observations', message: 'A observação é obrigatória' });
    }
    else {
      removeError('observations');
    }
  }

  function handleChangeAppointmentRecurrenceTypeId(e) {
    setAppointmentRecurrenceTypeId(e.target.value);

    if (!e.target.value) {
      setError({ field: 'appointmentRecurrenceTypeId', message: 'O tipo de recorrência é obrigatório' });
      setShowRecurrenceEndTime(false);
    }
    else {
      removeError('appointmentRecurrenceTypeId');

      if (e.target.value === 1 || e.target.value === '1') {
        setShowRecurrenceEndTime(true);
      }
      else {
        setShowRecurrenceEndTime(false);
      }
    }
  }

  function incrementCount(e) {
    setAppointmentRecurrenceUnity((appointmentRecurrenceUnity ? parseInt(appointmentRecurrenceUnity) + 1 : 1));
    e.target.value = (appointmentRecurrenceUnity ? parseInt(appointmentRecurrenceUnity) + 1 : 1);
    handleChangeAppointmentRecurrenceUnity(e)
  }
  function decrementCount(e) {
    let valueToSet = (appointmentRecurrenceUnity ? parseInt(appointmentRecurrenceUnity) - 1 : 1);
    if (valueToSet < 1) {
      valueToSet = 1;
    }
    setAppointmentRecurrenceUnity(valueToSet);
    e.target.value = valueToSet;
    handleChangeAppointmentRecurrenceUnity(e)
  }

  function handleChangeAppointmentRecurrenceUnity(e) {
    setAppointmentRecurrenceUnity(e.target.value);

    if (!e.target.value) {
      setError({ field: 'appointmentRecurrenceUnity', message: 'A quantidade da recorrência é obrigatóra' });
    }
    else {
      removeError('appointmentRecurrenceUnity');
    }
  }

  function handleAppointmentRecurrenceUnityOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }

    if (!input.value) {
      e.target.value = 1;
      setAppointmentRecurrenceUnity(1);
      handleChangeAppointmentRecurrenceUnity(e);
    }
  }

  function incrementCount2(e) {
    setHourAdvanceReminder((hourAdvanceReminder ? parseInt(hourAdvanceReminder) + 1 : 1));
    e.target.value = (hourAdvanceReminder ? parseInt(hourAdvanceReminder) + 1 : 1);
    handleChangeHourAdvanceReminder(e)
  }
  function decrementCount2(e) {
    let valueToSet = (hourAdvanceReminder ? parseInt(hourAdvanceReminder) - 1 : 1);
    if (valueToSet < 1) {
      valueToSet = 1;
    }
    setHourAdvanceReminder(valueToSet);
    e.target.value = valueToSet;
    handleChangeHourAdvanceReminder(e)
  }

  function handleChangeHourAdvanceReminder(e) {
    setHourAdvanceReminder(e.target.value);

    if (!e.target.value) {
      setError({ field: 'hourAdvanceReminder', message: 'A quantidade da recorrência é obrigatóra' });
    }
    else {
      removeError('hourAdvanceReminder');
    }
  }

  function handleHourAdvanceReminderOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }

    if (!input.value) {
      e.target.value = 1;
      setHourAdvanceReminder(1);
      handleChangeHourAdvanceReminder(e);
    }
  }

  function handleChangeAppointmentRecurrenceEndTime(e) {
    const inputValue = e.target.value;
    setAppointmentRecurrenceEndTime(inputValue);

    const validHHMMstring = (str) => /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str);

    if (!inputValue) {
      setError({ field: 'appointmentRecurrenceEndTime', message: 'O horário de fim da recorrência é obrigatório' });
    }
    else if (!validHHMMstring(inputValue)) {
      setError({ field: 'appointmentRecurrenceEndTime', message: 'Horario inválido' });
    } else {
      removeError('appointmentRecurrenceEndTime');
    }
  }

  function handleChangeAppointmentRecurrenceEndDate(e) {
    const inputValue = e.target.value;
    setAppointmentRecurrenceEndDate(inputValue);

    if (e.target.value.length < 10) {
      setError({ field: 'appointmentRecurrenceEndDate', message: 'Data inválida' });
      return false;
    }

    if (!inputValue) {
      setError({ field: 'appointmentRecurrenceEndDate', message: 'A data fim da recorrência é obrigatória' });
      return false;
    }
    else if (inputValue.length === 10 && (!isMatch(inputValue, 'dd/MM/yyyy'))) {
      setError({ field: 'appointmentRecurrenceEndDate', message: 'Data inválida' });
      return false;
    }
    else {
      removeError('appointmentRecurrenceEndDate');
    }

    try {
      const [day, month, year] = inputValue.split('/');
      const date = new Date(+year, month - 1, +day);
      var today = new Date();
      today.setHours(0, 0, 0, 0);

      if (date) {
        if (date < today) {
          setError({ field: 'appointmentRecurrenceEndDate', message: invalidDateLesserThanTodayMessage });
          return false;
        }
        else {
          setAppointmentRecurrenceEndDate(inputValue);
          removeError('appointmentRecurrenceEndDate');
        }
      }
    }
    catch (err) {
      setError({ field: 'appointmentRecurrenceEndDate', message: 'Data inválida' });
      return false;
    }
  }

  function handleChangeAppointmentHasRecurrence(e) {
    const inputValue = e.target.value;
    const appointmentHasRecurrence = (inputValue === 'true');
    setAppointmentHasRecurrence(appointmentHasRecurrence);

    if (!appointmentHasRecurrence) {
      setAppointmentRecurrenceTypeId(null);
    }

    if (appointmentHasRecurrence) {
      setReminderDate1('');
      setReminderDate1Date(null);
      removeError('reminderDate1');
      setReminderDate2('');
      setReminderDate2Date(null);
      removeError('reminderDate2');
      setReminderDate3('');
      setReminderDate3Date(null);
      removeError('reminderDate3');
    }
  }

  function handleChangeAppointmentHasHourAdvanceReminder(e) {
    const inputValue = e.target.value;
    const appointmentHasHourAdvanceReminder = (inputValue === 'true');
    setAppointmentHasHourAdvanceReminder(appointmentHasHourAdvanceReminder);
  }

  function handleChangeAppointmentTime(e) {
    const inputValue = e.target.value;
    setAppointmentTime(inputValue);

    const validHHMMstring = (str) => /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str);

    if (!inputValue && appointmentRecurrenceEndTime) {
      setError({ field: 'appointmentTime', message: 'A hora do compromisso é obrigatória' });
      setMustShowHourAdvanceReminderSwitch(false);
    }
    else if (!validHHMMstring(inputValue) && inputValue) {
      setError({ field: 'appointmentTime', message: 'Horario inválido' });
      setMustShowHourAdvanceReminderSwitch(false);
    } else {
      removeError('appointmentTime');
      setMustShowHourAdvanceReminderSwitch(true);
    }
  }

  function handleChangeDocumentValue(values, sourceInfo) {
    const value = (values.floatValue / 100);

    setRealDocumentValue(value); // valor real
    setDocumentValue(values.formattedValue); // valor formatado para exibição, somente
    console.log(sourceInfo);

    if (!value) {
      setError({ field: 'documentValue', message: 'O valor é obrigatório' });
    } else if (parseFloat(value) === 0) {
      setError({ field: 'documentValue', message: 'O valor não pode ser zero' });
    }
    else {
      removeError('documentValue');
    }
  }

  function handleChangeBarCodeNumber(e) {
    setBarCodeNumber(e.target.value);

    if (!e.target.value) {
      setError({ field: 'barCodeNumber', message: 'O código de barras é obrigatório' });
    }
    else {
      removeError('barCodeNumber');
    }
  }

  function handleAppointmentDateOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }

    var len = input.value.length;

    if (len === 0) {
      e.preventDefault();
      setError({ field: 'appointmentDate', message: 'Data inválida' });
    }
    else {
      removeError('appointmentDate');
    }

    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 2) {
        input.value += '/';
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        input.value += '/';
      }
    }
  }

  function handleAppointmentRecurrenceEndDateOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;

    if (len === 0) {
      e.preventDefault();
      setError({ field: 'appointmentRecurrenceEndDate', message: 'Data inválida' });
    }
    else {
      removeError('appointmentRecurrenceEndDate');
    }

    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 2) {
        input.value += '/';
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        input.value += '/';
      }
    }
  }

  function handleReminderDate1OnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;

    if (len === 0) {
      e.preventDefault();
      setError({ field: 'reminderDate1', message: 'Data inválida' });
    }
    else {
      removeError('reminderDate1');
    }

    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 2) {
        input.value += '/';
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        input.value += '/';
      }
    }
  }

  function handleReminderDate2OnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;

    if (len === 0) {
      e.preventDefault();
      setError({ field: 'reminderDate2', message: 'Data inválida' });
    }
    else {
      removeError('reminderDate2');
    }

    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 2) {
        input.value += '/';
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        input.value += '/';
      }
    }
  }

  function handleReminderDate3OnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;

    if (len === 0) {
      e.preventDefault();
      setError({ field: 'reminderDate3', message: 'Data inválida' });
    }
    else {
      removeError('reminderDate3');
    }

    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 2) {
        input.value += '/';
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        input.value += '/';
      }
    }
  }

  function handleAppointmentTimeOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;
    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      if (len === 2) {
        input.value += ':';
      }
    }
  }

  function handleChangeAppointmentDate(e) {
    const inputValue = e.target.value;
    setAppointmentDate(inputValue);

    if (e.target.value.length < 10) {
      setError({ field: 'appointmentDate', message: 'Data inválida' });
      return false;
    }

    if (!inputValue) {
      setError({ field: 'appointmentDate', message: 'A data do compromisso é obrigatória' });
      return false;
    }
    else if (inputValue.length === 10 && (!isMatch(inputValue, 'dd/MM/yyyy'))) {
      setError({ field: 'appointmentDate', message: 'Data inválida' });
      return false;
    }
    else {
      removeError('appointmentDate');
    }

    try {
      const [day, month, year] = inputValue.split('/');
      const date = new Date(+year, month - 1, +day);
      var today = new Date();
      today.setHours(0, 0, 0, 0);

      if (date) {
        if (date < today) {
          setError({ field: 'appointmentDate', message: invalidDateLesserThanTodayMessage });
          return false;
        }
        else if (date <= reminderDate2Date) {
          setError({ field: 'appointmentDate', message: invalidAppointmentDateLesserThanReminderDateMessage });
          return false;
        }
        else if (date <= reminderDate3Date) {
          setError({ field: 'appointmentDate', message: invalidAppointmentDateLesserThanReminderDateMessage });
          return false;
        }
        else if (date <= reminderDate1Date) {
          setError({ field: 'appointmentDate', message: invalidAppointmentDateLesserThanReminderDateMessage });
          return false;
        }
        else {
          setAppointmentDateDate(date);
          removeError('appointmentDate');
        }
      }
      else {
        setAppointmentDateDate(null);
      }
    }
    catch (err) {
      setError({ field: 'appointmentDate', message: 'Data inválida' });
      return false;
    }
  }

  function handleChangeReminderDate1(e) {
    setReminderDate1(e.target.value);

    if (e.target.value.length === 0) {
      removeError('reminderDate1');
      setShowReminderDate2(false);
      setShowReminderDate3(false);
    }

    if (e.target.value.length < 10) {
      setError({ field: 'reminderDate1', message: 'Data inválida' });
      return false;
    } else if (e.target.value.length === 10 && (!isMatch(e.target.value, 'dd/MM/yyyy'))) {
      setError({ field: 'reminderDate1', message: 'Data inválida' });
      return false;
    }
    else if (e.target.value.length === 10 && (e.target.value === appointmentDate)) {
      setError({ field: 'reminderDate1', message: invalidReminderDateLesserThanMessage });
      return false;
    }
    else if (e.target.value.length === 10) {
      const inputValue = e.target.value;
      try {
        const [day, month, year] = inputValue.split('/');
        const date = new Date(+year, month - 1, +day);
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        if (date) {
          setReminderDate1Date(date);

          if (date < today) {
            setError({ field: 'reminderDate1', message: invalidDateLesserThanTodayMessage });
            return false;
          }
          else if (date >= appointmentDateDate) {
            setError({ field: 'reminderDate1', message: invalidReminderDateAfteAppointmentDateMessage });
            return false;
          }
          else {
            removeError('reminderDate1');
            setShowReminderDate2(true);
            setShowReminderDate3(false);
          }
        }
        else {
          setReminderDate1Date(null);
        }
      }
      catch (err) {
        setError({ field: 'reminderDate1', message: 'Data inválida' });
        return false;
      }
    }
    else {
      setShowReminderDate2(false);
    }
  }

  function handleChangeReminderDate2(e) {
    setReminderDate2(e.target.value);

    if (e.target.value.length < 10) {
      setError({ field: 'reminderDate2', message: 'Data inválida' });
      return false;
    } else if (e.target.value.length === 10 && (!isMatch(e.target.value, 'dd/MM/yyyy'))) {
      setError({ field: 'reminderDate2', message: 'Data inválida' });
      return false;
    }
    else if (e.target.value.length === 10 && (e.target.value === reminderDate1)) {
      setError({ field: 'reminderDate2', message: invalidReminderDateLesserThanMessage });
      return false;
    }
    else if (e.target.value.length === 10) {
      const inputValue = e.target.value;
      try {
        const [day, month, year] = inputValue.split('/');
        const date = new Date(+year, month - 1, +day);
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        if (date) {
          setReminderDate2Date(date);

          if (date < today) {
            setError({ field: 'reminderDate2', message: invalidDateLesserThanTodayMessage });
            return false;
          }
          else if (date >= appointmentDateDate) {
            setError({ field: 'reminderDate2', message: invalidReminderDateAfteAppointmentDateMessage });
            return false;
          }
          else if (date <= reminderDate1Date) {
            setError({ field: 'reminderDate2', message: invalidReminderDateLesserThanMessage });
            return false;
          }
          else {
            removeError('reminderDate2');
            setShowReminderDate3(true);
          }
        }
        else {
          setReminderDate2Date(null);
        }
      }
      catch (err) {
        setError({ field: 'reminderDate2', message: 'Data inválida' });
        return false;
      }
    }
    else {
      setShowReminderDate3(false);
    }
  }

  function handleChangeReminderDate3(e) {
    setReminderDate3(e.target.value);

    if (e.target.value.length < 10) {
      setError({ field: 'reminderDate3', message: 'Data inválida' });
      return false;
    } else if (e.target.value.length === 10 && (!isMatch(e.target.value, 'dd/MM/yyyy'))) {
      setError({ field: 'reminderDate3', message: 'Data inválida' });
      return false;
    }
    else if (e.target.value.length === 10 && (e.target.value === reminderDate1 || e.target.value === reminderDate2)) {
      setError({ field: 'reminderDate3', message: invalidReminderDateLesserThanMessage });
      return false;
    }
    else if (e.target.value.length === 10) {
      const inputValue = e.target.value;
      try {
        const [day, month, year] = inputValue.split('/');
        const date = new Date(+year, month - 1, +day);
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        if (date) {
          setReminderDate3Date(date);

          if (date < today) {
            setError({ field: 'reminderDate2', message: invalidDateLesserThanTodayMessage });
            return false;
          }
          else if (date >= appointmentDateDate) {
            setError({ field: 'reminderDate3', message: invalidReminderDateAfteAppointmentDateMessage });
            return false;
          }
          else if (date <= reminderDate1Date) {
            setError({ field: 'reminderDate3', message: invalidReminderDateLesserThanMessage });
            return false;
          }
          else if (date <= reminderDate2Date) {
            setError({ field: 'reminderDate3', message: invalidReminderDateLesserThanMessage });
            return false;
          }
          else {
            removeError('reminderDate3');
          }
        }
        else {
          setReminderDate3Date(null);
        }
      }
      catch (err) {
        setError({ field: 'reminderDate3', message: 'Data inválida' });
        return false;
      }
    }
  }

  function handleDeleteReminder1(e) {
    setReminderDate1('');
    setShowReminderDate2(false);
    removeError('reminderDate1');

  }

  function handleDeleteReminder2(e) {
    setReminderDate2('');
    setShowReminderDate2(false);
    removeError('reminderDate2');
  }

  function handleDeleteReminder3(e) {
    setReminderDate3('');
    setShowReminderDate3(false);
    removeError('reminderDate3');
  }

  function handleOpenModal(e) {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  async function handleDelete(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);

    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
      OriginalAppointmentId: appointmentToDelete.OriginalAppointmentId ?? appointmentToDelete.Id,
    });

    window.open('/', '_self');
  }

  async function handleDeleteRecurrenceOnly(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);

    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
    });

    window.open('/', '_self');
  }

  function handleOpenDeleteModal(e) {
    setAppointmentToDelete(appointment);
    setIsModalDeleteOpen(true);
  }

  function handleOpenModalOptions(e) {
    setAppointmentToDelete(appointment);
    setIsModalOptionsOpen(true);
  }

  function handleCloseDeleteModal() {
    setIsModalDeleteOpen(false);
  }

  function handleCloseModalOptions() {
    setIsModalOptionsOpen(false);
  }

  async function handleRemoveAttachment() {
    setIsModalOpen(false);
    setIsLoading(true);

    await onRemoveAttachment();
    setMustShowAttachmentFileInput(true);
    setIsLoading(false);

  }

  function handleChangeAppointmentFiles(e) {
    if (e.target.files[0]) {
      setAppointmentFiles(e.target.files[0]);
    }
  }

  function handleChangeAppointmentType(option) {
    setAppointmentTypeOption(option);

    if (option) {
      setAppointmentTypeId(option.value);
    } else {
      setAppointmentTypeId(null);
    }

    if (!option) {
      setError({ field: 'appointmentTypeId', message: 'O tipo é obrigatório' });
    }
    else {
      removeError('appointmentTypeId');
    }
  }

  return (

    <Form onSubmit={handleSubmit} noValidate>
      <Loader isLoading={isLoading} />
      <Modal
        title='Tem certeza que deseja remover o anexo?'
        body='Esta ação não poderá ser desfeita'
        isVisible={isModalOpen}
        danger
        onConfirm={handleRemoveAttachment}
        onCancel={handleCloseModal}
      />

      <Modal
        title='Tem certeza que deseja remover o compromisso?'
        body='Esta ação não poderá ser desfeita'
        isVisible={isModalDeleteOpen}
        danger
        onConfirm={handleDelete}
        onCancel={handleCloseDeleteModal}
      />

      <ModalOptions
        title='Tem certeza que deseja remover o compromisso?'
        body='[IMPORTANTE] Este compromisso possui recorrências'
        isVisible={isModalOptionsOpen}
        danger
        onCancel={handleCloseModalOptions}
        onOption1={handleDelete}
        onOption2={handleDeleteRecurrenceOnly}
        option1Text='Apagar todos'
        option2Text='Apagar somente esta instância'
      />

      <div>Tipo</div>
      <FormGroup
        error={getErrorMessageByFieldName('appointmentTypeId')}
      >
        <CreatableSelect
          isClearable
          onChange={(newValue) => handleChangeAppointmentType(newValue)}
          onCreateOption={handleCreateAppointmentType}
          options={appointmentTypes && (
            appointmentTypes.map((appointmentType) => (
              createOption(appointmentType.Id, appointmentType.Name)
            )))}
          formatCreateLabel={(inputText) => `Criar ${inputText}`}
          value={appointmentTypeOption}
          placeholder='Pesquise aqui...'
          isDisabled={isFieldsDisabled}
        />
      </FormGroup>

      <div>Data do compromisso</div>
      <FormGroup
        error={getErrorMessageByFieldName('appointmentDate')}
      >
        <Calendar
          onChange={
            (date) => {
              setAppointmentDateDate(date);
              setAppointmentDate(date.toLocaleDateString('pt-br', { year: "numeric", month: "numeric", day: "numeric" }));

              // removeError('appointmentDate');
              var today = new Date();
              today.setHours(0, 0, 0, 0);

              if (date) {
                if (date < today) {
                  setError({ field: 'appointmentDate', message: invalidDateLesserThanTodayMessage });
                  return false;
                }
                else if (date <= reminderDate2Date) {
                  setError({ field: 'appointmentDate', message: invalidAppointmentDateLesserThanReminderDateMessage });
                  return false;
                }
                else if (date <= reminderDate3Date) {
                  setError({ field: 'appointmentDate', message: invalidAppointmentDateLesserThanReminderDateMessage });
                  return false;
                }
                else if (date <= reminderDate1Date) {
                  setError({ field: 'appointmentDate', message: invalidAppointmentDateLesserThanReminderDateMessage });
                  return false;
                }
                else {
                  setAppointmentDateDate(date);
                  removeError('appointmentDate');
                }
              }
              else {
                setAppointmentDateDate(null);
              }
            }
          }
          locale="pt-br"
          dateFormat="dd/MM/yyyy"
          selected={appointmentDateDate}
          placeholder='__/__/____'
          customInput={
            <Input
              type="text"
              maxLength="10"
              placeholder='Data do compromisso (dd/mm/aaaa)'
              value={appointmentDate}
              onChange={handleChangeAppointmentDate}
              onKeyPress={handleAppointmentDateOnKeyPress}
              onKeyUp={handleAppointmentDateOnKeyPress}
              error={getErrorMessageByFieldName('appointmentDate')}
              disabled={isFieldsDisabled}
            />
          }
        />
      </FormGroup>

      {mustShowAppointmentTime && (
        <>
          <div>Hora do compromisso</div>
          <FormGroup
            error={getErrorMessageByFieldName('appointmentTime')}
          >
            <Input
              type='text'
              value={appointmentTime}
              maxLength="5"
              placeholder='Hora do compromisso'
              onChange={handleChangeAppointmentTime}
              onKeyPress={handleAppointmentTimeOnKeyPress}
              onKeyUp={handleAppointmentTimeOnKeyPress}
              error={getErrorMessageByFieldName('appointmentTime')}
              disabled={isFieldsDisabled}
            />
          </FormGroup>

          {mustShowHourAdvanceReminderSwitch && (
            <>
              <div>Lembrete de horário próximo?</div>
              <FormGroup
                error={getErrorMessageByFieldName('appointmentHasHourAdvanceReminder')}
              >
                <RadioButton
                  type='radio'
                  name='radio2'
                  id='radio3'
                  value={true}
                  checked={appointmentHasHourAdvanceReminder === true}
                  onChange={handleChangeAppointmentHasHourAdvanceReminder}
                  error={getErrorMessageByFieldName('appointmentHasHourAdvanceReminder')}
                  disabled={isFieldsDisabled}
                />
                <label for='radio3'>
                  Sim
                </label>

                <RadioButton
                  type='radio'
                  name='radio2'
                  id='radio4'
                  value={false}
                  checked={appointmentHasHourAdvanceReminder === false}
                  onChange={handleChangeAppointmentHasHourAdvanceReminder}
                  error={getErrorMessageByFieldName('appointmentHasHourAdvanceReminder')}
                  disabled={isFieldsDisabled}
                />
                <label for='radio4'>
                  Não
                </label>
              </FormGroup>
            </>
          )}

          {(appointmentHasHourAdvanceReminder && mustShowHourAdvanceReminderSwitch) && (
            <>
              <div>Enviar com antecedência de:</div>
              <div>
                <FormGroup
                  error={getErrorMessageByFieldName('hourAdvanceReminder')}
                  className='complexcounter'
                >
                  <div className="group recurrenceunit">
                    <button
                      type="button"
                      onClick={decrementCount2}
                      className="counter"
                    >-</button>
                    <Input
                      type='text'
                      value={hourAdvanceReminder}
                      placeholder='0'
                      onChange={handleChangeHourAdvanceReminder}
                      onKeyPress={handleHourAdvanceReminderOnKeyPress}
                      onKeyUp={handleHourAdvanceReminderOnKeyPress}
                      error={getErrorMessageByFieldName('hourAdvanceReminder')}
                      disabled={isFieldsDisabled}
                    />
                    <button
                      type="button"
                      onClick={incrementCount2}
                      className="counter"
                    >+</button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    Horas
                  </div>
                </FormGroup>

              </div>
            </>
          )
          }
        </>
      )}

      {!appointmentHasRecurrence && (
        <>
          <div>Lembrar também em</div>
          <FormGroup
            error={getErrorMessageByFieldName('reminderDate1')}
            className='complex'
          >
            <div className="group">
              <Calendar
                onChange={
                  (date) => {
                    setReminderDate1Date(date);
                    setReminderDate1(date.toLocaleDateString('pt-br', { year: "numeric", month: "numeric", day: "numeric" }));

                    // removeError('appointmentDate');
                    var today = new Date();
                    today.setHours(0, 0, 0, 0);

                    if (date) {
                      setReminderDate1Date(date);

                      if (date < today) {
                        setError({ field: 'reminderDate1', message: invalidDateLesserThanTodayMessage });
                        return false;
                      }
                      else if (date >= appointmentDateDate) {
                        setError({ field: 'reminderDate1', message: invalidReminderDateAfteAppointmentDateMessage });
                        return false;
                      }
                      else {
                        removeError('reminderDate1');
                        setShowReminderDate2(true);
                        setShowReminderDate3(false);
                      }
                    }
                    else {
                      setReminderDate1Date(null);
                    }
                  }
                }
                locale="pt-br"
                dateFormat="dd/MM/yyyy"
                selected={reminderDate1Date}
                placeholder='__/__/____'
                customInput={
                  <Input
                    type="text"
                    value={reminderDate1}
                    maxLength="10"
                    placeholder='(dd/mm/aaaa)'
                    onChange={handleChangeReminderDate1}
                    onKeyPress={handleReminderDate1OnKeyPress}
                    onKeyUp={handleReminderDate1OnKeyPress}
                    error={getErrorMessageByFieldName('reminderDate1')}
                    disabled={isFieldsDisabled}
                  />
                }
              />
              {(!isFieldsDisabled) && (
                <button
                  type="button"
                  onClick={(e) => {
                    handleDeleteReminder1(e);
                  }}
                >
                  <img src={Trash} alt='Trash' />
                </button>
              )}
              {/* {showDeleteReminderDate1 && (
            <button
              type="button"
              onClick={(e) => {
                handleDeleteReminder1(e);
              }}
            >
              <img src={Trash} alt='Trash' />
            </button>
          )} */}
            </div>
          </FormGroup>

          {showReminderDate2 && (
            <FormGroup
              error={getErrorMessageByFieldName('reminderDate2')}
              className='complex'
            >
              <div className="group">
                <Calendar
                  onChange={
                    (date) => {
                      setReminderDate2Date(date);
                      setReminderDate2(date.toLocaleDateString('pt-br', { year: "numeric", month: "numeric", day: "numeric" }));

                      // removeError('appointmentDate');
                      var today = new Date();
                      today.setHours(0, 0, 0, 0);

                      if (date) {
                        setReminderDate2Date(date);

                        if (date < today) {
                          setError({ field: 'reminderDate2', message: invalidDateLesserThanTodayMessage });
                          return false;
                        }
                        else if (date >= appointmentDateDate) {
                          setError({ field: 'reminderDate2', message: invalidReminderDateAfteAppointmentDateMessage });
                          return false;
                        }
                        else if (date <= reminderDate1Date) {
                          setError({ field: 'reminderDate2', message: invalidReminderDateLesserThanMessage });
                          return false;
                        }
                        else {
                          removeError('reminderDate2');
                          setShowReminderDate3(true);
                        }
                      }
                      else {
                        setReminderDate2Date(null);
                      }
                    }
                  }
                  locale="pt-br"
                  dateFormat="dd/MM/yyyy"
                  selected={reminderDate2Date}
                  placeholder='__/__/____'
                  customInput={
                    <Input
                      type="text"
                      value={reminderDate2}
                      maxLength="10"
                      placeholder='(dd/mm/aaaa)'
                      onChange={handleChangeReminderDate2}
                      onKeyPress={handleReminderDate2OnKeyPress}
                      onKeyUp={handleReminderDate2OnKeyPress}
                      error={getErrorMessageByFieldName('reminderDate2')}
                      disabled={isFieldsDisabled}
                    />
                  }
                />
                {(!isFieldsDisabled) && (
                  <button
                    type="button"
                    onClick={(e) => {
                      handleDeleteReminder2(e);
                    }}
                  >
                    <img src={Trash} alt='Trash' />
                  </button>
                )}
              </div>
            </FormGroup>
          )}

          {showReminderDate3 && (
            <FormGroup
              error={getErrorMessageByFieldName('reminderDate3')}
              className='complex'
            >
              <div className="group">
                <Calendar
                  onChange={
                    (date) => {
                      setReminderDate3Date(date);
                      setReminderDate3(date.toLocaleDateString('pt-br', { year: "numeric", month: "numeric", day: "numeric" }));

                      // removeError('appointmentDate');
                      var today = new Date();
                      today.setHours(0, 0, 0, 0);

                      if (date) {
                        setReminderDate3Date(date);

                        if (date < today) {
                          setError({ field: 'reminderDate2', message: invalidDateLesserThanTodayMessage });
                          return false;
                        }
                        else if (date >= appointmentDateDate) {
                          setError({ field: 'reminderDate3', message: invalidReminderDateAfteAppointmentDateMessage });
                          return false;
                        }
                        else if (date <= reminderDate1Date) {
                          setError({ field: 'reminderDate3', message: invalidReminderDateLesserThanMessage });
                          return false;
                        }
                        else if (date <= reminderDate2Date) {
                          setError({ field: 'reminderDate3', message: invalidReminderDateLesserThanMessage });
                          return false;
                        }
                        else {
                          removeError('reminderDate3');
                        }
                      }
                      else {
                        setReminderDate3Date(null);
                      }
                    }
                  }
                  locale="pt-br"
                  dateFormat="dd/MM/yyyy"
                  selected={reminderDate3Date}
                  placeholder='__/__/____'
                  customInput={
                    <Input
                      type="text"
                      value={reminderDate3}
                      maxLength="10"
                      placeholder='(dd/mm/aaaa)'
                      onChange={handleChangeReminderDate3}
                      onKeyPress={handleReminderDate3OnKeyPress}
                      onKeyUp={handleReminderDate3OnKeyPress}
                      error={getErrorMessageByFieldName('reminderDate3')}
                      disabled={isFieldsDisabled}
                    />
                  }
                />
                {(!isFieldsDisabled) && (
                  <button
                    type="button"
                    onClick={(e) => {
                      handleDeleteReminder3(e);
                    }}
                  >
                    <img src={Trash} alt='Trash' />
                  </button>
                )}
              </div>
            </FormGroup>
          )}
        </>
      )}

      {mustShowAppointmentRecurrenceOptions && (
        <>
          <div>Tem Recorrência?</div>
          <FormGroup
            error={getErrorMessageByFieldName('appointmentHasRecurrence')}
          >
            <RadioButton
              type='radio'
              name='radio'
              id='radio1'
              value={true}
              checked={appointmentHasRecurrence === true}
              onChange={handleChangeAppointmentHasRecurrence}
              error={getErrorMessageByFieldName('appointmentHasRecurrence')}
              disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
            />
            <label for='radio1'>
              Sim
            </label>

            <RadioButton
              type='radio'
              name='radio'
              id='radio2'
              value={false}
              checked={appointmentHasRecurrence === false}
              onChange={handleChangeAppointmentHasRecurrence}
              error={getErrorMessageByFieldName('appointmentHasRecurrence')}
              disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
            />
            <label for='radio2'>
              Não
            </label>
          </FormGroup>

          {appointmentHasRecurrence && (
            <>
              <div>A cada</div>
              <div>
                <FormGroup
                  error={getErrorMessageByFieldName('appointmentRecurrenceUnity')}
                  className='complexcounter'
                >
                  <div className="group recurrenceunit">
                    <button
                      type="button"
                      onClick={decrementCount}
                      className="counter"
                    >-</button>
                    <Input
                      type='text'
                      value={appointmentRecurrenceUnity}
                      placeholder='0'
                      onChange={handleChangeAppointmentRecurrenceUnity}
                      onKeyPress={handleAppointmentRecurrenceUnityOnKeyPress}
                      onKeyUp={handleAppointmentRecurrenceUnityOnKeyPress}
                      error={getErrorMessageByFieldName('appointmentRecurrenceUnity')}
                      disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                    />
                    <button
                      type="button"
                      onClick={incrementCount}
                      className="counter"
                    >+</button>
                  </div>
                </FormGroup>
                <FormGroup
                  error={getErrorMessageByFieldName('appointmentRecurrenceTypeId')}
                >
                  <Select
                    value={appointmentRecurrenceTypeId}
                    onChange={handleChangeAppointmentRecurrenceTypeId}
                    error={getErrorMessageByFieldName('appointmentRecurrenceTypeId')}
                    placeholder='Selecione um período'
                    disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                  >
                    <option value="">Selecione um período</option>
                    {appointmentRecurrenceTypes && (
                      appointmentRecurrenceTypes.map((appointmentRecurrenceType) => (
                        <option key={appointmentRecurrenceType.Id} value={appointmentRecurrenceType.Id}>{appointmentRecurrenceType.Name}</option>
                      )))}
                  </Select>
                </FormGroup>
              </div>

              <div>Data de fim da recorrência</div>
              <FormGroup
                error={getErrorMessageByFieldName('appointmentRecurrenceEndDate')}
              >
                <Calendar
                  onChange={
                    (date) => {
                      setAppointmentRecurrenceEndDateDate(date);
                      setAppointmentRecurrenceEndDate(date.toLocaleDateString('pt-br', { year: "numeric", month: "numeric", day: "numeric" }));

                      // removeError('appointmentDate');
                      var today = new Date();
                      today.setHours(0, 0, 0, 0);

                      if (date) {
                        if (date < today) {
                          setError({ field: 'appointmentRecurrenceEndDate', message: invalidDateLesserThanTodayMessage });
                          return false;
                        }
                        else {
                          setAppointmentRecurrenceEndDateDate(date);
                          removeError('appointmentRecurrenceEndDate');
                        }
                      }
                      else {
                        setAppointmentRecurrenceEndDate(null);
                      }
                    }
                  }
                  locale="pt-br"
                  dateFormat="dd/MM/yyyy"
                  selected={appointmentRecurrenceEndDateDate}
                  placeholder='__/__/____'
                  customInput={
                    <Input
                      type="text"
                      value={appointmentRecurrenceEndDate}
                      maxLength="10"
                      placeholder='Data de fim da recorrência (dd/mm/aaaa)'
                      onChange={handleChangeAppointmentRecurrenceEndDate}
                      onKeyPress={handleAppointmentRecurrenceEndDateOnKeyPress}
                      onKeyUp={handleAppointmentRecurrenceEndDateOnKeyPress}
                      error={getErrorMessageByFieldName('appointmentDate')}
                      disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                    />
                  }
                />
              </FormGroup>

              {
                showRecurrenceEndTime && (
                  <>
                    <div>Hora de fim da recorrência</div>
                    <FormGroup
                      error={getErrorMessageByFieldName('appointmentRecurrenceEndTime')}
                    >
                      <Input
                        type='text'
                        value={appointmentRecurrenceEndTime}
                        maxLength="5"
                        placeholder='Hora de fim da recorrência'
                        onChange={handleChangeAppointmentRecurrenceEndTime}
                        onKeyPress={handleAppointmentTimeOnKeyPress}
                        onKeyUp={handleAppointmentTimeOnKeyPress}
                        error={getErrorMessageByFieldName('appointmentRecurrenceEndTime')}
                        disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                      />
                    </FormGroup>
                  </>
                )
              }
            </>
          )
          }
        </>
      )}
      {
        mustShowDocumentValue && (
          <>
            <div>Valor</div>
            <FormGroup
              error={getErrorMessageByFieldName('documentValue')}
            >
              {/* <CurrencyInput
              id="document-value"
              name="document-value"
              placeholder="Valor"
              value={documentValue}
              decimalSeparator=','
              decimalsLimit={2}
              decimalScale={2}
              className='document-value'
              onValueChange={(value, name) => handleChangeDocumentValue(value, name)}
              error={getErrorMessageByFieldName('documentValue')}
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            /> */}

              <MyCustomNumberFormat
                id="document-value"
                name="document-value"
                className='document-value'
                placeholder="Valor"
                value={documentValue}
                onValueChange={(values, sourceInfo) => {
                  handleChangeDocumentValue(values, sourceInfo);
                }}
                error={getErrorMessageByFieldName('documentValue')}
                disabled={isFieldsDisabled}
              />
            </FormGroup>
          </>
        )
      }

      {
        mustShowBarCodeNumber && (
          <>
            <div>Código de barras</div>
            <FormGroup
              error={getErrorMessageByFieldName('barCodeNumber')}
            >
              <Input
                type='text'
                value={barCodeNumber}
                placeholder='Código de barras'
                onChange={handleChangeBarCodeNumber}
                error={getErrorMessageByFieldName('barCodeNumber')}
                disabled={isFieldsDisabled}
              />
            </FormGroup>
          </>
        )
      }

      <div>Observação</div>
      <FormGroup
        error={getErrorMessageByFieldName('name')}
      >
        <Input
          type='text'
          value={observations}
          placeholder='Observação'
          onChange={handleChangeObservations}
          error={getErrorMessageByFieldName('observations')}
          disabled={isFieldsDisabled}
        />
      </FormGroup>

      {
        (
          (appointment.AppointmentFileUrl || mustShowAttachmentFileInput)
        ) && (
          <>
            <div>Anexo</div>
            <FormGroup
              error={getErrorMessageByFieldName('appointmentFiles')}
            >
              {(!mustShowAttachmentFileInput) && (
                <>
                  <a
                    href={appointment.AppointmentFileUrl}
                    target='_blank'
                    rel="noreferrer">
                    <span>Visualizar</span>
                  </a> &nbsp;&nbsp;
                  <Button
                    type='button'
                    onClick={handleOpenModal}
                    neutral
                  >
                    Remover anexo
                  </Button>
                </>
              )}

              {(mustShowAttachmentFileInput) && (
                <input
                  type="file"
                  name="appointmentFiles"
                  onChange={handleChangeAppointmentFiles}
                  error={getErrorMessageByFieldName('appointmentFiles')}
                  disabled={isFieldsDisabled}
                />
              )}
            </FormGroup>
          </>
        )
      }

      <ButtonContainer>
        <Button
          type='submit'
          disabled={((!isFormValid()))}
        >
          {buttonLabel}
        </Button>
      </ButtonContainer>

      {
        (appointment.Id !== 0) && (
          <ButtonContainer>
            <Button
              type='button'
              danger
              onClick={(e) => {
                if (!appointment.AppointmentHasRecurrences) {
                  handleOpenDeleteModal(e, appointment);
                }
                else {
                  handleOpenModalOptions(e, appointment);
                }
              }}
              disabled={isFieldsDisabled}
            >
              Excluir compromisso
            </Button>
          </ButtonContainer>
        )
      }

    </Form >
  );
}