import HttpClient from './utils/HttpClient';

class SharedAppointmentsService {
  constructor(baseUrl) {

    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async listAppointments(userId) {
    // const urlPath = '/shared-appointments/get-all{}';
    const urlPath = `/shared-appointments/get-all/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async getAppointment(id) {
    const urlPath = `/shared-appointments/get/${id}`;

    return this.httpClient.get(urlPath);
  }

  async getByAppointmentIdAndOwnerUserId(appointmentId, ownerUserId) {
    const urlPath = `/shared-appointments/get/${appointmentId}/owner-user/${ownerUserId}`;

    return this.httpClient.get(urlPath);
  }

  async delete(request) {
    const urlPath = `/shared-appointments/delete`;

    return this.httpClient.post(urlPath, request);
  }

  async shareAppointments(request) {
    const urlPath = `/shared-appointments/share`;

    console.log(request);

    return this.httpClient.post(urlPath, request);
  }

  async getAllPendingOfApproval(userId) {
    const urlPath = `/shared-appointments/get-all-pending-approval/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async manageApproval(request) {
    const urlPath = `/shared-appointments/manage-approval`;

    console.log(request);

    return this.httpClient.post(urlPath, request);
  }
}

export default SharedAppointmentsService;