import { useState } from 'react';
import {
  Form,
  ButtonContainer,
  // Container
} from './styles';
import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';
import useErrors from '../../hooks/useErrors';
import Loader from '../Loader';

export default function AppointmentTypeForm({
  appointmentType,
  onSubmit,
}) {
  const [name, setName] = useState(appointmentType.Name);

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = (name && errors.length === 0);
  const [isLoading, setIsLoading] = useState(false);
  // const [isCreateUserSuccess, setIsCreateUserSuccess] = useState(false);
  // const [serviceError, setServiceError] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);

    const response = await
      onSubmit({
        name,
      });


    if (response.Error) {
      // setServiceError(response.Error.Message);
      alert(response.Error.Message);
    }
    // else {
    //   setServiceError(null);
    // }

    // setIsCreateUserSuccess(true);
    // setIsLoading(false);
    handleGoToList();
  }

  async function handleGoToList() {
    window.open('/appointment-types', '_self');
  }

  function handleChangeName(e) {
    setName(e.target.value);

    if (!e.target.value) {
      setError({ field: 'name', message: 'o nome é obrigatório' });
    } else {
      removeError('name');
    }
  }

  return (
    <Form onSubmit={handleSubmit} noValidate >
      <div>Nome</div>
      <FormGroup
        error={getErrorMessageByFieldName('name')}
      >
        <Input
          type="text"
          value={name}
          placeholder="Nome"
          onChange={handleChangeName}
          error={getErrorMessageByFieldName('name')}
        />
      </FormGroup>

      <Loader isLoading={isLoading} />

      <ButtonContainer>
        <Button
          type="button"
          disabled={!isFormValid}
          onClick={(e) => handleSubmit(e)}

        >
          Salvar
        </Button>
      </ButtonContainer>

    </Form>
  );
}