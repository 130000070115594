import { Container } from "./styles";
import PropTypes from 'prop-types';

export default function FormGroup({
  children,
  error,
  className,
}) {
  return (
    <Container
    className={className}
    >
      {children}
      {error && (
        <>
          {/* <br /> */}
          <small>{error}</small>
        </>
      )}
    </Container>
  );
}

FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  className: PropTypes.string,
}

FormGroup.defaultProps = {
  error: null,
}