import { useState } from 'react';
import { Form, ButtonContainer, Container } from './styles';
import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';
import useErrors from '../../hooks/useErrors';
import Loader from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
  // faDollarSign,
} from '@fortawesome/free-solid-svg-icons'

export default function UserForm({
  onSubmit,
}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = (name && email && password && errors.length === 0);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateUserSuccess, setIsCreateUserSuccess] = useState(false);
  const [serviceError, setServiceError] = useState('');

  function isPasswordValid(password) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return regex.test(password);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);

    const response = await
      onSubmit({
        name,
        email,
        password
      });


    if (response.Error) {
      setServiceError(response.Error.Message);
    }
    else {
      setServiceError(null);
    }

    setIsCreateUserSuccess(true);
    setIsLoading(false);
  }

  async function handleGoToLogin(e) {
    e.preventDefault();
    e.stopPropagation();

    window.open('/login', '_self');
  }

  function handleChangeName(e) {
    setName(e.target.value);

    if (!e.target.value) {
      setError({ field: 'name', message: 'o nome é obrigatório' });
    } else {
      removeError('name');
    }
  }

  function handleChangeEmail(e) {
    setEmail(e.target.value);

    if (!e.target.value) {
      setError({ field: 'email', message: 'o e-mail é obrigatório' });
    } else {
      removeError('email');
    }
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);

    if (!e.target.value) {
      setError({ field: 'password', message: 'a senha é obrigatória' });
    }
    else if (!isPasswordValid(e.target.value)) {
      setError({ field: 'password', message: 'a senha deve conter um mínimo de 8 letras, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula e pelo menos um número' });
    }
    else {
      removeError('password');
    }
  }

  function handleChangeConfirmePassword(e) {
    setConfirmPassword(e.target.value);

    if (!e.target.value) {
      setError({ field: 'confirmPassword', message: 'digite a mesma senha novamente' });
    } else {

      if (password !== e.target.value) {
        setError({ field: 'confirmPassword', message: 'digite a mesma senha novamente' });
        return;
      }
      else if (!isPasswordValid(e.target.value)) {
        setError({ field: 'confirmPassword', message: 'a senha deve conter um mínimo de 8 letras, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula e pelo menos um número' });
      }
      else {
        removeError('confirmPassword');
      }
    }
  }

  return (
    (!isCreateUserSuccess) ? (
      <Form onSubmit={handleSubmit} noValidate >
        <div>Nome</div>
        <FormGroup
          error={getErrorMessageByFieldName('name')}
        >
          <Input
            type="text"
            value={name}
            placeholder="Nome"
            onChange={handleChangeName}
            error={getErrorMessageByFieldName('name')}
          />
        </FormGroup>

        <div>E-mail</div>
        <FormGroup
          error={getErrorMessageByFieldName('email')}
        >
          <Input
            type="text"
            value={email}
            placeholder="E-mail"
            onChange={handleChangeEmail}
            error={getErrorMessageByFieldName('email')}
          />
        </FormGroup>

        <div>Senha</div>
        <FormGroup
          error={getErrorMessageByFieldName('password')}
        >
          <div className="input-group-btn">
            <Input
              type={passwordType}
              value={password}
              placeholder="Senha"
              onChange={handleChangePassword}
              error={getErrorMessageByFieldName('password')}
            />
            <button type="button" className="btn" onClick={togglePassword}>
              {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
            </button>
          </div>
        </FormGroup>

        <div>Confirmar senha</div>
        <FormGroup
          error={getErrorMessageByFieldName('confirmPassword')}
        >
          <div className="input-group-btn">
            <Input
              type={passwordType}
              value={confirmPassword}
              placeholder="Confirmar senha"
              onChange={handleChangeConfirmePassword}
              error={getErrorMessageByFieldName('confirmPassword')}
            />
            <button type="button" className="btn" onClick={togglePassword}>
              {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
            </button>
          </div>
        </FormGroup>

        <Loader isLoading={isLoading} />

        <ButtonContainer>
          <Button
            type="button"
            disabled={!isFormValid}
            onClick={(e) => handleSubmit(e)}

          >
            Continuar
          </Button>
        </ButtonContainer>

      </Form>
    ) : (
      <Container>
        <h5>
          {(serviceError) ? (
            <>
              {serviceError}
            </>
          ) : (
            <>
              Cadastro Realizado com sucesso <br />Te enviamos um e-mail para que você confirme o seu cadastro
            </>
          )}
        </h5>

        {(serviceError) ? (
          <ButtonContainer>
            <Button
              type="button"
              onClick={(e) => setIsCreateUserSuccess(false)}
            >
              Alterar os dados e tentar novamente
            </Button>
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <Button
              type="button"
              onClick={(e) => handleGoToLogin(e)}
            >
              Clique aqui para fazer login
            </Button>
          </ButtonContainer>
        )}
      </Container>
    )
  );
}