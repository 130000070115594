/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Container,
  // Header,
  ListContainer,
  Card,
  ErrorContainer,
  CardsContainer
} from './styles';
import Trash from '../../assets/icons/trash.svg';
import Edit from '../../assets/icons/edit.svg';
// import Share from '../../assets/icons/share.svg';
// import ArrowUp from '../../assets/icons/arrow-up.svg';
// import { InputSearchContainer } from '../Header/styles';
import FormGroup from '../FormGroup';
import Loader from '../Loader';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
// import { format } from 'date-fns';
import AppointmentTypesService from '../../services/AppointmentTypesService';
import AppointmentCategoriesService from '../../services/AppointmentCategoriesService';
import Button from '../Button';
import Modal from '../Modal';
// import ModalOptions from '../ModalOptions';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
// import PlusSign from '../../assets/icons/plus-sign.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faFolder,
//   faCalendarDay,
//   faBell,
//   faInfoCircle,
//   faRecycle,
//   // faDollarSign,
// } from '@fortawesome/free-solid-svg-icons'
// // https://fontawesome.com/v5/cheatsheet
// import PropTypes from 'prop-types';

export default function AppointmentTypes({ fetchOnlyActive, appointmentTypeId, startDate, endDate }) {
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentCategories, setAppointmentCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  // const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  const [appointmentTypeToDelete, setAppointmentTypeToDelete] = useState(null);
  // const [appointmentToShare, setAppointmentToShare] = useState(null);
  // const [isOptionsVisible, setOptionsVisible] = useState(false);
  // const [plusSignClass, setPlusSignClass] = useState("");
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const listAppointmentTypes = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentTypesList = await new AppointmentTypesService(Config.API_BASE_URL).listAppointmentTypesByUserId(sessionObject.userId);
      setAppointmentTypes(appointmentTypesList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject.userId]);

  const listAppointmentCategories = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentCategoriessList = await new AppointmentCategoriesService(Config.API_BASE_URL).listAppointmentCategories();
      setAppointmentCategories(appointmentCategoriessList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject.userId]);

  async function handleReload() {
    await listAppointmentCategories();
    await listAppointmentTypes();
  };

  useEffect(() => {
    listAppointmentCategories();
    listAppointmentTypes();
  }, [listAppointmentTypes, listAppointmentCategories]);

  async function handleDelete(e) {
    setIsModalOpen(false);
    // setIsModalOptionsOpen(false);

    var response = await new AppointmentTypesService(Config.API_BASE_URL).Delete({
      Id: appointmentTypeToDelete.Id,
    });

    if (response.Error) {
      // setServiceError(response.Error.Message);
      alert(response.Error.Message);
    }
    else {
      await handleReload();
    }
  }

  function handleOpenModal(e, appointmentType) {
    setAppointmentTypeToDelete(appointmentType);
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }


  return (
    <Container>
      <Loader isLoading={isLoading} />

      <Modal
        title='Tem certeza que deseja remover o tipo de compromisso?'
        body='Esta ação não poderá ser desfeita'
        isVisible={isModalOpen}
        danger
        onConfirm={handleDelete}
        onCancel={handleCloseModal}
      />

      {/* <Header>
        <strong>
          {appointments.length}
          {appointments.length === 1 ? ' compromisso' : ' compromissos'}
        </strong>
      </Header> */}
      <ListContainer>
        {/* <header>
          <button type='button' className='sort-button'>
            <span>Nome <img src={ArrowUp} alt='Arrow Up' /></span>
          </button>
        </header> */}
      </ListContainer>

      {hasError && (
        <ErrorContainer>
          <strong>
            Ocorreu um erro ao retornar os tipos de compromissos
          </strong>
          <Button
            type="button"
            onClick={(e) => { handleReload(); }}
          >
            Tentar novamente
          </Button>
        </ErrorContainer>
      )}

      {!hasError && (
        <>
          <CardsContainer>
            {appointmentCategories && (
              appointmentCategories.map((appointmentCategory) => (
                <>
                  <br />
                  <h3>{appointmentCategory.Name}</h3>
                  <br />
                  <FormGroup>
                    <Button
                      type='submit'
                      onClick={(e) => {
                        window.open(`/appointment-types/new/${appointmentCategory.Id}`, '_self');
                      }}
                    // onClick={handleClearFilters}
                    >
                      + Novo tipo
                    </Button>
                  </FormGroup>
                  {/* <br /> */}

                  {appointmentTypes && (
                    appointmentTypes.filter((appointmentType) => appointmentType.AppointmentCategoryId === appointmentCategory.Id).map((appointmentType) => (

                      <Card
                        key={`id-${appointmentType.Id}`}
                      >
                        <div className="info">
                          {/* <div className="appointment-name">
                      <strong>{appointment.AppointmentCategoryName}</strong>
                    </div> */}

                          <div className="span">
                            <strong>
                              {appointmentType.Name}
                            </strong>
                          </div>

                        </div>

                        <div className="actions">
                          <a href={`/appointment-types/edit/${appointmentType.Id}`}>
                            <img src={Edit} alt='Edit' />
                          </a>

                          {
                            (!appointmentType.IsInUse) && (
                              <button
                                type="button"
                                onClick={(e) => {
                                  handleOpenModal(e, appointmentType);
                                }}
                              >
                                <img src={Trash} alt='Trash' />
                              </button>
                            )
                          }
                        </div>
                      </Card>
                    ))
                  )}
                  <br />
                </>
              ))
            )}
          </CardsContainer>
        </>
      )}
    </Container >
  );
}

// AppointmentTypes.propTypes = {
// }

// Appointments.defaultProps = {
//   error: null,
// }