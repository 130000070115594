import styled, { css } from "styled-components";

export default styled.input`  
  /* width: 100%;
  background: #FFF;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
  height: 52px; */
  border: 2px solid #FFF;
  margin-right: 4px;
  /* border-radius: 4px; */
  outline: none;
  /* padding: 0 16px;
  font-size: 16px;
  transition: border-color 0.2s ease-in;
  appearance: none; */
  cursor: pointer;

  &:focus{
    border: 2px solid ${({ theme }) => theme.colors.primary.main};
  }

  ${({ theme, error }) => error && css`
    color: ${theme.colors.danger.main};
    border-color: ${theme.colors.danger.main} !important;

    &:focus{
      color: ${theme.colors.danger.light};
      border-color: ${theme.colors.danger.main} !important;
    }

  `};
`;
